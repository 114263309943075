<template>
  <div class="wrapper">
    <img :src="getImg()" class="breaker" :class="{'breaker-active': is_focused}"
        @click.right.exact="toggleSignal(); setSignal()" 
        @click.middle="toggleLocked(); setSignal()"
        @click.left="toggleFocused()"/>
    <div class="container">
        <span class="breaker-label"> {{elementName}} </span>
    </div>
  </div>
</template>

<script>
import breaker_closed from '@/assets/breaker_closed.png';
import breaker_open from '@/assets/breaker_open.png';
import breaker_closed_locked from '@/assets/breaker_closed_locked.png';
import breaker_open_locked from '@/assets/breaker_open_locked.png';
import breaker_closed_vertical from '@/assets/breaker_closed_vertical.png';
import breaker_open_vertical from '@/assets/breaker_open_vertical.png';
import breaker_closed_locked_vertical from '@/assets/breaker_closed_locked_vertical.png';
import breaker_open_locked_vertical from '@/assets/breaker_open_locked_vertical.png';
export default {
    props: {
        signalName: String,
        cmdName: String,
        isVertical: Boolean,
        elementRef: Number,
        elementName: String
    },
    data() {
        return {
            name: this.signalName,
            value: 0,
            cmd_name: this.cmdName,
            cmd_value: 0,
            cmd_cycles: -1,
            is_focused: false,
            warning_flag: true
        }    
    },
    computed: {
        //getting signal list from model to component local variable
        signals() {
            return this.$store.getters.simuSignals
        },
        breaker_open_c() {
            if (this.isVertical) {
                return breaker_open_vertical
            } else {
                return breaker_open
            }
        },
        breaker_closed_c() {
            if (this.isVertical) {
                return breaker_closed_vertical
            } else {
                return breaker_closed
            }
        },
        breaker_closed_locked_c() {
            if (this.isVertical) {
                return breaker_closed_locked_vertical
            } else {
                return breaker_closed_locked
            }
        },
        breaker_open_locked_c() {
            if (this.isVertical) {
                return breaker_open_locked_vertical
            } else {
                return breaker_open_locked
            }
        },
        edit_mode() {
            return this.$store.state.grid_show
        }
    },
    methods: {
        setSignal() {

            if (this.edit_mode) {
                return
            }

            this.$store.dispatch('setSignal', {
                signal_name: this.cmdName, 
                val: this.cmd_value, 
                cycles: this.cmd_cycles,
                vm: this
            })
        },
        breaker_signal() {
            if( this.signals.length > 0) {
                try {
                    this.value = this.signals.find(element => element.name === this.name).value
                }
                catch {
                    this.value = 0
                    if (this.warning_flag) {
                        this.warning_flag = false
                        this.$toast.add({severity:'error', summary: 'Wrong signal', detail: `Signal ${this.name} is not defined in simulation model, please ensure you are using scenario which match with model`, life: 5000});
                    }
                }
            }
        },
        //breakers logic after clicking scroll - setting values and cycles for request
        toggleLocked() {
            switch (this.value) {
                case 0:
                    this.cmd_value = 3
                    this.cmd_cycles = -1
                    break;
                case 1:
                    this.cmd_value = 4
                    this.cmd_cycles = -1
                    break;
                case 2:
                    this.cmd_value = 1
                    this.cmd_cycles = 10
                    break;   
                case 3:
                    this.cmd_value = 2
                    this.cmd_cycles = 10
                    break;                         
            }
        },
        //breakers logic after clicking LMB and setting values for request
        toggleSignal() {
            switch (this.value) {
                case 0:
                    this.cmd_value = 2
                    break;
                case 1:
                    this.cmd_value = 1
                    break;
                case 2:
                    this.cmd_value = 4
                    break;   
                case 3:
                    this.cmd_value = 3
                    break;                         
            }
        },
        //breaker focus change
        toggleFocused() {
            this.is_focused = !this.is_focused
        },
        getImg() {
           if (this.value === 0) {
               return this.breaker_open_c
           } else if (this.value === 1) {
               return this.breaker_closed_c
           } else if (this.value === 2) {
               return this.breaker_open_locked_c 
           } else if (this.value === 3) {
               return this.breaker_closed_locked_c
           }
        }   
    },
    watch: {
        //updating breaker value from model
        signals() {
            this.breaker_signal()
        },
    }
}   

    
</script>

<style scoped>
.breaker-active {
    outline: 2px solid #9e211fab;
    outline-offset: -3px;
    border-radius: 12px;
    box-shadow: 0 2px 3px rgba(0,0,0,0.5);        
}
.breaker {
    width: 125px;
    height: 125px;
}
.breaker:hover {
    cursor: pointer;
}
.makevertical {
    transform: rotate(90deg);
}
.wrapper {
    position: relative;
    width: 125px;
    height: 125px;
}
.breaker-label {
    margin-top: 0px;
    position: absolute;
    font-size: 13px;
    font-weight:600;
    font-family: var(--font-2-basic);
    color: var(--font3);
    width: 150px;
}
.container {
    position: absolute;
    top: 0;
}
</style>
