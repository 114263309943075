<template>
    <div>
        <button class="btn">
            <div class="top">
                <slot></slot>
            </div>
            <div class="bottom">
                <slot name="bottom"></slot>
            </div>
        </button>
    </div>
</template>

<script>
    export default {
        
    }
</script>

<style scoped>
/* .btn {
    color: #fff;
    font-weight: 550;
    text-transform: uppercase;
    text-decoration: none;
    background: #9e211f;
    padding: 20px;
    border-radius: 5px;
    display: inline-block;
    border: none;
    transition: all 0.4s ease 0s;
} */
.btn {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: #fff;
    font-weight: 550;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 5px;
    transition: all 0.4s ease 0s;
    border: none;
    padding: 20px;
    min-width: 5.1rem;
    height: 3.4rem;
    background: #9e211f;
}
.btn:hover {
    background: var(--woodward_grey);
    -webkit-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    -moz-box-shadow: 0px 5px 40px -10px rgba(0,0,0,0.57);
    box-shadow: 5px 40px -10px rgba(0,0,0,0.57);
    transition: all 0.4s ease 0s; 
    cursor: pointer;
}
.top {
    order: 0
}
.bottom {
    font-size: 0.6rem !important;
    color: #d7d7d7;
    order: 1;
}
</style>