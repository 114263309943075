<template>
    <div>
        <div>
            <h2 class="font-2-basic header-title">Manage scenario</h2>
        </div>
        <EditSimulation @close-dialog="$emit('close-dialog')" />
    </div>
</template>

<script>
    import EditSimulation from '@/components/functions/EditSimulation.vue'
    export default {
        components: {
            EditSimulation
        },
        data() {
            return {
                showEditSimulation: false,
                showCreateSimulation: false
            }
        },
        methods: {
            toggleEditSimulationDialog() {
                this.showCreateSimulation = false
                this.showEditSimulation = !this.showEditSimulation
            },
            toggleCreateSimulationDialog() {
                this.showEditSimulation = false
                this.showCreateSimulation = !this.showCreateSimulation
            },
        }
    }
</script>


<style scoped>
    .nav-buttons {
        width: 100%;
    }
    .header-title {
        font-size: 1.2rem;
        color: var(--font1);
    }
    .loader-wrapper {
        display: flex;
        width: 100%;
        height: 20rem;
        justify-content: center;
        align-items: center;
    }
</style>