<template>
    <div class="customize-element-text-wrapper">
        <span class="text-label-wrapper" :style="`font-size: ${font_size}px; color: var(--${font_color}); justify-content: ${justify}; align-items: ${align}`">
            <span v-for="line in labelContent" :key="line" class="text-label-content"> {{line}} </span>
        </span>
    </div>
</template>

<script>
export default {
    props: {
        elementRef: Number,
        labelProperties: String,
        elementName: String
    },
    data() {
        return {
            font_size: null,
            font_color: null,
            justify: null,
            align: null
        }
    },
    methods: {
    },
    computed: {
        labelContent() {
            return this.elementName ? this.elementName.split('\n') : this.elementName
        }
    },
    created() {
        if (this.elementName) {
            this.label_content = this.elementName
        }
        if (this.labelProperties) {
            const label_properties_splited = this.labelProperties.split(',')
            this.font_size = label_properties_splited[0]
            this.font_color = label_properties_splited[1]
            this.justify = label_properties_splited[2]
            this.align = label_properties_splited[3]
        }
    },
}
</script>

<style scoped>
.customize-element-text-wrapper {
    width: 100%;
}
.text-label-wrapper {
    display: flex;
    flex-direction: column;
    height: 120px; 
    max-height: 125px;
    overflow-wrap: anywhere !important;
    word-wrap: anywhere !important;
    white-space:pre;
}

</style>