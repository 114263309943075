<template>
    <span class="item">
        <span class='wrapper'>
            <transition name="submenu" mode="out-in">    
                <img :src="getImg()" class="item-ico" :style="opacity_style" @click.exact="showSubmenu()" v-if="!submenu_show"> 
                    <SimuItemPanel
                    :signal-names="signalNames" 
                    :signal-commands="signalCommands"
                    :signal-units="signalUnits"
                    :element-type="elementType"
                    :element-name="elementName"
                    :element-values="this.values"
                    @toggle-submenu="showSubmenu" 
                    @save-values="saveValues"
                    v-else />
            </transition>
            <span class="signals" :style="hide_signals">
                <div class="item-label">{{elementName}}</div>
                <div v-for="(sig, index) in signals" :key="sig" :index="index" v-show="signalNames[0] != ''"> 
                    <div v-if="!edit_mode" class="signals-wrapper">
                        <div class="format-mid">{{ sig.value.toFixed(2) }}</div> 
                        <div class="format-right">{{ sig.unit }}</div>
                    </div>
                    <div v-else class="signals-wrapper">
                        <div class="format-mid-names">{{ sig.name }}</div>
                    </div>
                </div>
            </span> 
        </span>   
    </span>
</template>

<script>
    import SimuItemPanel from '@/components/items_library/SimuItemPanel'
    export default {
        components: {  
            SimuItemPanel
        },
        props: {
            signalNames: Array,
            signalUnits: Array,
            signalCommands: Array,
            elementRef: Number,
            elementType: String,
            elementName: String
        },
        data() {
            return {
                submenu_show: false,
                values: []
            }
        },
        methods: {
            //showing submenu after clicking on item and closing all signal views
            showSubmenu() {
                if (this.edit_mode) {
                    return
                }
                this.submenu_show = !this.submenu_show;
            },
            //chosing item image by reading first word of signal names ! do przerobienia !
            getImg() {
                return this.$store.getters.getTheme === 'theme-light' ? require(`@/assets/${this.elementType}.png`) : require(`@/assets/${this.elementType}_darkmode.png`) 
            },
            saveValues(arg) {
                this.values = arg
            }  
        },
        computed: {
            //changing opacity after clicking show_singals button
            opacity_style() {
                return {opacity: 0.4}
            },
            hide_signals() {
                return this.submenu_show ? {opacity: 0} : null
            },
            //generating signal list from signal names and saving them as a object and then updating values from model 
            signals() {
                var signals = [];
                let y = 0;
                for (var name of this.signalNames) {
                    signals[y] = {name: name, value: 0, unit: this.signalUnits[y]}
                    y += 1
                }
                for(const signal of this.$store.getters.simuSignals) {
                        const finder = signals.findIndex(element => element.name === signal.name);
                        if (finder !== -1) {
                            signals[finder].value = signal.value
                        }
                    }   
                return signals
            },
            edit_mode() {
                return this.$store.state.grid_show
            }
        },
    }
</script>

<style scoped>
.signals-wrapper {
    display: flex;
}
.item-label {
    position: absolute;
    top:-15px;
    font-size: 13px;
    font-weight:600;
    font-family: var(--font-2-basic);
    color: var(--font3);
    width: 150px;
}
.wrapper {
    display:flex;
    position: relative;
    width:150px;
    height:145px;
}
.signals {
    top: 15%;
    margin: 0 0 0 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    font-size: 18px;
    font-weight:600;
    font-family: var(--font-2-basic);
    color: var(--font3);
}
.item-ico {
    width: 125px;
    height: 120px;
    justify-content: center;
    z-index: 5;
}
.item-ico:hover {
    cursor: pointer;
}

.submenu-enter-from,
.submenu-leave-to {
    opacity: 0;
}
.submenu-enter-active {
    transition: opacity 0.3s ease-out;
}
.submenu-leave-active {
    transition: opacity 0.3s ease-in;
}
.submenu-enter-to,
.submenu-leave-from {
    opacity: 1;
}
.format-mid-names {
    display: flex;
    max-width: 110px;
    overflow: hidden;
    font-size: 1rem;
    margin-top: 0.1rem;
    color: var(--green);
}


</style>