<template>
  <div class="diode-container">
    <span class="title-label"> {{elementName}} </span>
    <div v-for="(diode, index) in signalNames" :key="diode" :index="index" class="diodes-wrapper">
        <div class="diode-row">
            <img class="diode-element-img" style="opacity: 1;" :src="checkState(signalNames[index]) === 1 ? greenDiode : redDiode" />
            <span class="diode-description"> {{signal_units ? signal_units[index] : null}} </span>
        </div>
    </div>
    <div class="title-container" v-if="$store.state.signals_show">
        <span class="title-label"> {{elementName}} </span>
    </div>
  </div>
</template>

<script>
import green_diode from '@/assets/green_diode.png'
import red_diode from '@/assets/red_diode.png'
export default {
    props: {
        signalNames: Array,
        elementName: String,
        elementDiodes: Number,
        signalUnits: String,
        isVertical: Boolean,
        elementRef: Number
    },
    data() {
        return {
            signal_units: null
        }    
    },
    computed: {
        greenDiode() {
            return green_diode
        },
        redDiode() {
            return red_diode
        },
        simuSignals() {
            return this.$store.getters.simuSignals.filter(signal => { return this.signalNames.includes(signal.name) } )
        },
    },
    methods: {
        checkState(signal) {
            if (!signal) {
                return 0
            }
            return this.simuSignals.length > 0 ? this.simuSignals.find( ({ name }) => name === signal ).value : 0
        }
    },
    created() {
        if (this.signalUnits) {
            this.signal_units = this.signalUnits.split(',')
        }
    },
}   

    
</script>

<style scoped>
.diode-container {
    display: flex;
    position: relative;
    flex-direction: column;
    width: 125px;
    height: 125px;
    z-index: 5;
}
.diode-element-img {
    width: 20px;
    height: 20px;
}
.diode-description {
    color: var(--font3);
    font-size: 0.8rem;
    margin: 0.1rem 0 0 0.5rem;
}
.diodes-wrapper {
    display: flex;
    flex-direction: column !important;
}
.diode-row {
    display: flex;
    width: 100%;
    flex-direction: row;
    margin: 0 0;
}
.title-label {
    font-size: 13px;
    font-weight:600;
    font-family: var(--font-2-basic);
    color: var(--font3);
    text-align: center;
    margin-bottom: 5px;
    top: 0;
    left: 40%;
}
.title-container {
    position: absolute;
    top: -9px;
    left: 0;
    width: 100%;
}
</style>
