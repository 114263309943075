<template>
    <div class="item" ref="eg_item">
        <div class='wrapper'>
            <img :src="getImg()" :class="{ rotate: isLS6 }" class="item-ico" @click="showSubmenu($event)"> 
            <teleport to="body">       
                <EasyGenPanel
                class="panel"
                :signal-names="signalNames" 
                :signal-commands="signalCommands"
                :submenu-cords="cords"
                :element-name="elementName"
                :signal-units="signalUnits"
                :default-fav="defaultFav"
                @toggle-submenu="showSubmenu()"
                @save-cords="saveLastCords"
                v-show="submenu_show" />    
            </teleport>  
        </div> 
        <div class="container">
            <span :class="elementType !== 'inverter' ? 'segment-label' : 'segment-label-inverter'"> {{elementName}} </span>
        </div>
    </div>
</template>

<script>
    import EasyGenPanel from '@/components/items_library/EasyGenPanel.vue'
    export default {
        components: {  
            EasyGenPanel
        },
        props: {
            signalNames: Array,
            signalCommands: Array,
            defaultFav: Array,
            elementName: String,
            elementType: String,
            signalUnits: Array,
        },
        data() {
            return {
                submenu_show: false,
                inputs: [],
                cords: {left: null, top: null},
                cords_saved: false
            }
        },
        methods: {
            //after clicking on item submenu will appear
            showSubmenu(event) {

                if (!this.submenu_show) {
                    if (!this.cords_saved) {
                        this.cords.top = event.clientY
                        this.cords.left = event.clientX
                    }
                }
                this.submenu_show = !this.submenu_show;
            },
            saveLastCords(cords) {
                this.cords_saved = true
                this.cords = cords
            },
            getImg() {
                return require(`@/assets/${this.elementType}.png`)
            }
        },
        computed: {
            isLS6() {
                return this.elementType === 'ls6'
            },
            edit_mode() {
                return this.$store.state.grid_show
            }
        }
    }
</script>

<style scoped>
.wrapper {
    display:flex;
    position: relative;
    width:150px;
    height:150px;
}
.item {
    position: relative;
}
.signals {
    top: 10%;
    margin: 0 0 0 10px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    position: absolute;
    font-size: 18px;
    font-weight:500;
    font-family: 95% Arial, Helvetica, sans-serif;
    color:#9e211f;
}
.item-ico {
    /* width: 125px;
    height: 120px;
    justify-content: center;
    padding: 5%; */
    width: 125px;
    height: 125px;
    object-fit: contain;
    justify-content: center;
}
.item-ico:hover {
    cursor: pointer;
}
.submenu-enter-from,
.submenu-leave-to {
    opacity: 0;
}
.submenu-enter-active {
    transition: opacity 0.3s ease-out;
}
.submenu-leave-active {
    transition: opacity 0.3s ease-in;
}
.submenu-enter-to,
.submenu-leave-from {
    opacity: 1;
}
.segment-label {
    margin-left: 1.2rem;
    margin-top: -9.8rem;
    position: absolute;
    font-size: 15px;
    font-weight:600;
    font-family: var(--font-2-basic);
    color: var(--font3);
    width: 150px;
}
.segment-label-inverter {
    margin-left: 1.2rem;
    margin-top: -11rem;
    position: absolute;
    font-size: 15px;
    font-weight:600;
    font-family: var(--font-2-basic);
    color: var(--font3);
    width: 150px;
}
.container {
    position: absolute;
}
.rotate {
    margin-top: 5px;
    width: 125px;
    height: 90px;

}


</style>