<template>
    <transition name="submenu">
        <div class="form-container" :style="{top: cords.top + 'px', left: cords.left + 'px'}">
            <div class="close" @click="submit()" > X </div>
            <span class="name"> {{elementName}} </span>
            <it-icon class="mover" name="open_with" draggable="false" outlined @mousedown.prevent="drag()" @mouseup="stop()"></it-icon>
            <div class="form wwd-col">
                <div v-for="(input,index) in signals" :key="input" :lp="index">
                    <div class="element-row wrapper wwd-sb" v-if="input.fav || show_all"> 
                        <Tag
                        class="di-tag radio-allow"
                        :value="input.name.includes('DI') || input.name.includes('DO') ? input.name.split('_').slice(-2).join('_') : input.name.split('_')[2]" 
                        :color="Boolean(signals[index].value) ? 'green' : 'red' "
                        :severity="Boolean(signals[index].value) ? 'success' : 'danger' "
                        @click="setSignal(index)"
                        />
                        <span :style="input.display !== 'No description' ? 'color: var(--blue1)' : 'color: var(--font1)'" class="input-display">
                            {{input.display}}
                        </span>
                    </div>
                </div>
            </div>    
                <it-button type="danger" class="eg-button" @click="showAll()"> {{buttonLabel}} </it-button>
            </div>
    </transition>
</template>

<script>
    import Tag from 'primevue/tag';
    export default {
        components: {
            Tag
        },
        props: {
            signalNames: Array,
            signalCommands: Array,
            defaultFav: Array,
            digitalInputs: Array,
            submenuCords: Object,
            elementName: String,
            signalUnits: Array,
        },    
        data() {
            return {
                infinity_cycles: true,
                cycles: null,
                inputs: this.digitalInputs,
                names: this.signalNames,
                show_all: false,
                cords: this.submenuCords,
                timer: null,
                new_signals: null,
                constant: 11,
                isMoving: false
            }
        },
        methods: {
            //after clicking set submenu will close
            submit() {
                this.$emit('saveCords', this.cords)
                this.$emit('toggle-submenu')
            },
            setSignal(index) {
                this.$store.dispatch('setSignal', {signal_name: this.signals[index].name, val: this.bus(this.signals[index].value), cycles: -1, vm: this});
            },
            showAll() {
                this.show_all = !this.show_all
            },
            toggleDrag() {
                this.isMoving = !this.isMoving
            },
            drag() {
                document.body.addEventListener('mousemove', this.move);
            },
            move(ev) {
                this.cords.top = ev.clientY -10 ;
                this.cords.left = ev.clientX -12 ;
            },
            stop() {
                document.body.removeEventListener('mousemove', this.move, false);
            },
            createSignals() {
                var signals = [];
                let y = 0;
                for (var x of this.signalNames) {
                    if (this.signalUnits) {
                        signals[y] = {name: x, value: 0, fav: this.defaultFav[y], display: this.signalUnits[y] ? this.signalUnits[y] : 'No description'}
                        y += 1
                    } else {
                        signals[y] = {name: x, value: 0, fav: this.defaultFav[y], display: 'No description'}
                        y += 1
                    }
                }
                this.new_signals = signals
            },
            sub(is_checked) {
                return is_checked ? -1 : 0
            },
            bus(value) {
                return value ? 0 : 1
            }
        },
        computed: {
            //checking if number of cycles is != -1 and if is returning number of cycles
            signals() {
                var signals = this.new_signals
                for(const signal of this.$store.getters.simuSignals) {
                        const finder = signals.findIndex(element => element.name === signal.name);
                        if (finder !== -1) {
                            signals[finder].value = signal.value
                        }
                    }   
                return signals
            },
            giveCords() {
                return this.mouse
            },
            buttonLabel() {
                return this.show_all ? 'Show fav' : 'Show all'
            }
        },
        beforeMount() {
            this.createSignals();
        }
    }    
</script>

<style scoped>
.form-container{
    font: 95% Arial, Helvetica, sans-serif;
    min-width: 5rem !important;
    padding: 16px;
    background: var(--color9);
    color: var(--font1);
    border: solid 0.5px var(--color3);
    position: fixed;
    z-index: 50;
    min-width: 135px;
    border-radius: 4px;
}
.form {
    margin-top: 10px;
}
.wrapper {
    box-shadow: 1px red;
}
.element-row {
    margin: 0.2rem 0;
    height: 1.2rem;
}
.element-row > * {
    margin: 0 0.3rem;
}
.item {
    padding: 1px;
}
.label {
    width: 55px;
    font-size: 14px;
    font-family: var(--font_poppins);
}
.radio {
    position: relative;
    margin-left: 3px;
    font-size: 19px !important;
}
.name {
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font_poppins);
    padding: 4px;
    font-size: 17px;
    border-bottom: 1.5px solid;
    border-color: var(--woodward_grey);
}
.radio-allow:hover {
    cursor: pointer;
}
.radio-deny:hover {
    cursor: not-allowed;
}
.close {
    position: absolute;
    left: 90%;
    top: 2%;
    width: 32px;
    height: 32px;
    opacity: 0.3;
}
::v-deep(.p-togglebutton.p-button) {
    width: 20px;
    height: 20px;
    background-color: #689F38 !important;
    color: white !important;
    box-shadow: unset !important;
}    
::v-deep(.p-togglebutton.p-button.p-highlight) {
    background-color: var(--woodward_red) !important;
    border-color: var(--color3) !important;
}  
::v-deep(.p-togglebutton.p-button:not(.p-disabled)) {
    border: 1px solid var(--border1) !important;
}
::v-deep(.p-togglebutton.p-button .p-button-icon-left, .p-togglebutton.p-button .p-button-icon-right) {
    color: white !important;
}
/* ::v-deep(.p-togglebutton.p-button.p-highlight .p-button-icon-left, .p-togglebutton.p-button.p-highlight .p-button-icon-right) {
    color: green !important
} */

.eg-button {
    background-color: var(--woodward_red) !important;
    border: 1px solid var(--woodward_red) !important;
    box-shadow: none;
    width: 100%;
    margin-top: 0.6rem;
    height: 20px;
}
.eg-button:hover {
    background-color: var(--woodward_red-light) !important;
}
.eg-input:deep(.it-input-wrapper) {
    height: 50% !important;
    width: 80%;
    height: 10px;
    margin: 5px 0px 5px 0px;
}
.eg-input:deep(.it-input) {
    padding: 4px 5px !important;
}
.close:hover,
.mover:hover {
    opacity: 1;
    color: var(--woodward_red);
    cursor: pointer;
}
.star {
    color: var(--woodward_grey);
    margin-right: 3px;
}
.star_checked {
    color: var(--woodward_red);
    margin-right: 3px;
}
.star:hover, .star_checked:hover {
    cursor: pointer;
}
.sname {
    color: #555;
    font: 80% Arial, Helvetica, sans-serif;
}
.mover {
    position: absolute;
    left: 5px;
    top: 5px;
    opacity: 0.3;
}
.p-tag.p-tag-danger {
    background-color: var(--woodward_grey);
    color: #ffffff;
}
.di-tag {
    min-width: 5.8rem;
    max-width: 5.8rem;
    overflow: hidden;
    font-size: 0.6rem;
    text-overflow: ellipsis;
}
.input-display {
    min-width: 8rem;
    max-width: 8rem;
    overflow: hidden;
}
.info-tag {
    color: var(--font3)
}
.info-tag:deep(.p-tooltip-text) {
    background-color: red !important;
}
/* .p-divider.p-divider-vertical:before {
    border-color: var(--color7) !important;
} */
.p-divider.p-divider-vertical {
    padding-top: 1.5rem !important;
}
.p-divider-vertical:before {
    height: 60% !important;
}





</style>