<template>
    <div>
        <div class="create-simulation-container wwd-column">
            <div class="input-group wwd-row">
                <it-input v-model="$store.getters.chosedScenario" disabled class="name" prefix-icon="short_text"
                    label-top="Scenario name: " />
                <it-input :disabled="!$store.getters.userPreferences?.advanced_mode" v-model="row_number" type="number" prefix-icon="table_rows" label-top="Rows*"
                    class="wwd-color" />
                <it-input :disabled="!$store.getters.userPreferences?.advanced_mode" v-model="column_number" type="number" prefix-icon="view_week" label-top="Columns*"
                    class="wwd-color" />
            </div>
            <div class="wwd-col">
                <it-select :disabled="!$store.getters.userPreferences?.advanced_mode" class="itselect" label-top="Signal list" v-model="signal_list"
                    :options="Object.keys($store.getters.signalSets)" />
            </div>
            <div class="wwd-col">
                <it-select class="itselect" label-top="Setup sequence" v-model="setup_sequence"
                    :options="$store.getters.sequencesListKeys" />
            </div>
            <div class="add-tag-group" v-if="$store.getters.userPreferences?.advanced_mode">
                <it-input  v-model="scenario_tag" style="width: 20rem" class="scenario-tag-name" prefix-icon="label"
                    label-top="Add scenario tag " />
                <Button :disabled="!$store.getters.userPreferences?.advanced_mode" @click="addTag()" icon="pi pi-plus" label="Add tag"
                    class="p-button p-button-secondary p-button-sm add-label-button"></Button>
            </div>
            <div class="tags-info-group font-color">
                <span> Tags </span>
                <i v-tooltip="'Tags are used to identify scenarios that matches the model being run. Tag default and user are reserved tags'"
                    class="pi pi-question-circle"></i>
            </div>
            <div class="tags-group">
                <Tag @click="deleteTag(index)" v-for="(tag, index) in tags" :key="tag" :index="index" severity="success"
                    :value="tag" class="scenario-tag"> </Tag>
            </div>
        </div>
        <div class="wwd-center">
            <div class="wwd-column wwd-center">
                <small v-if="error_flag" style="color: var(--red1); margin: 0 auto"> Fill all required forms to edit
                    scenario </small>
                <Button @click="editSimulation()" label="Save" icon="pi pi-check"
                    class="p-button p-button-primary create-label-button"></Button>
            </div>
        </div>
    </div>
</template>

<script>
    import Tag from 'primevue/tag'
    import Button from 'primevue/button'
    export default {
        components: {
            Tag,
            Button,
        },
        data() {
            return {
                row_number: '',
                column_number: '',
                scenario_tag: '',
                setup_sequence: null,
                tags: [],
                error_flag: false,
                models: [],
                signal_list: null
            }
        },
        methods: {
            async editSimulation() {
                if (!this.row_number || !this.column_number) {
                    this.error_flag = true
                    return
                }
                await this.$store.dispatch('editSimulation', {
                    row_number: this.row_number,
                    column_number: this.column_number,
                    scenario_tags: this.tags,
                    setup_sequence: this.setup_sequence,
                    signal_list: this.signal_list
                    
                })
                this.$emit('close-dialog')
            },
            addTag() {
                if (!this.$store.getters.userPreferences?.advanced_mode) {
                    return
                }
                if (this.tags.includes(this.scenario_tag)) {
                    return
                }
                if (this.scenario_tag) {
                    this.tags.push(this.scenario_tag)
                    this.scenario_tag = ''
                }
            },
            deleteTag(index) {
                if (!this.$store.getters.userPreferences?.advanced_mode) {
                    return
                }
                if(this.tags[index].toLowerCase().includes('default')) {
                    return
                }
                this.tags.splice(index, 1)
            }
        },
        created() {
            this.column_number = this.$store.getters.size.columns
            this.row_number = this.$store.getters.size.rows
            this.tags = this.$store.getters.scenarioTags ? this.$store.getters.scenarioTags : []
            this.setup_sequence = this.$store.getters.setupSequence || null
            this.signal_list = this.$store.getters.signalSet
        }
    }
</script>


<style scoped>
    .create-simulation-container {
        gap: 0.5rem;
    }
    .name {
        width: 18rem;
    }
    .submit {
        margin-top: 10px;
        width: 100px;
        border-color: var(--woodward_red);
        color: var(--woodward_red);
    }
    .wwd-color:hover {
        background-color: #9e211f15;
    }
    .switch-wrapper {
        display: flex;
        margin-top: 1rem;
        width: 100%;
    }
    .it-switch-wrapper {
        width: 13rem !important;
    }
    .itselect:deep(.it-select-selection) {
        width: 29.1rem !important;
    }
    .name:deep(.it-input-wrapper) {
        width: 18rem !important;
    }
    .select-wrapper {
        margin-top: 10px;
    }
    .input-group {
        gap: 0.2rem;
    }
    .add-tag-group {
        display: flex;
        flex-direction: row;
        height: 2rem;
        align-items: center;
        margin-top: 1rem;
        gap: 0.2rem;
    }
    .add-label-button {
        margin-top: 1.5rem;
    }    .tags-info-group {
        display: flex;
        flex-direction: row;
        gap: 0.2rem;
        margin-top: 0.8rem;
        
    }
    .tags-group {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        overflow: hidden;
        gap: 0.2rem;
        height: max-content;

    }
    .tags-group > * {
        width: 7rem;
        transition: 0.3s all;
    }
    .scenario-tag {
        background-color: var(--color5);
        color: var(--font1);
        width: fit-content;
    }
    .scenario-tag:hover {
        cursor: pointer;
        background-color: rgb(110, 0, 0);
        content: 'Delete' !important;
    }
    ::v-deep(.p-dropdown-label) {
        display: none !important;
    }
    ::v-deep(.p-dropdown-trigger) {
        height: 2.15rem !important;
        background-color: #607d8b;
        border-radius: 4% !important;
        color: var(--font1);
    }
    .create-label-button {
        margin-top: 1.5rem;
        width: 50%;
        background-color: rgb(129, 162, 252);
        border-color: rgb(129, 162, 252);
    }
</style>