<template>
    <div>
        <Toast class="toast" position="bottom-right" />

        <Message :closable="false" class="message-bus" :sticky="'sticky'" severity="info" v-if="$store.getters.activeComment">
            <div class="message-wrapper">
                <span>
                    {{ $store.getters.activeComment }}
                </span>
            </div>
        </Message>

        <!-- <Sidebar v-model:visible="$store.getters.sidebarVisible" position="right">  -->
        <Sidebar :visible="$store.getters.sidebarVisible" position="right" class="sidebar" @hide="showSequencerSideBar(false)" :show-close-icon="false" :modal="false"> 
            <template #header class=".p-header">
                <div class="header-wrapper">
                    <div class="sequence-header"> 
                        <i @click="showSequencerSideBar(false)" class="pi pi-angle-double-right close-sequencer-icon"></i>
                        <div v-tooltip="'Simulation time'"> <i class="pi pi-clock" style="font-size: 1.3rem; margin-right: 0.5rem"></i> {{ calculatedSimuTime }} </div>
                    </div>
                    <div class="sequence-container">
                        <div class="ww-col" >
                            <div v-if="$store.getters.activeSequence" style="margin-bottom: 5px;">
                                <Checkbox id="auto-scroll" label="Auto scroll" v-model="autoscroll" :binary="true">  </Checkbox>
                                <label for="auto-scroll" class="ml-2"> Auto scroll </label>
                            </div>
                            <Button v-if="$store.getters.activeSequence" @click="restartSequence()" class="p-button p-button-sm" style="height: 2rem" icon="pi pi-refresh" label="Restart sequence"  />
    
                        </div>

                        <div class="sequencer-name">
                            <div> Active sequence </div>
                            <Tag v-if="$store.getters.activeSequence" severity="success" text="test" label="test">{{ $store.getters.activeSequence.name.label }}</Tag>
                            <Tag v-else severity="secondary">No active sequence</Tag>
                        </div>
                    </div>
                </div>
            </template>
            
            <div class="sequence-wrapper" v-if="$store.getters.actionsToDo.length > 0">
                <div>
                    <span class="sequence-row preview-header">
                        <span class="sequence-step"> 
                                <span> Step </span> 
                            </span>
                            <span class="sequence-action">
                                <span> Action </span>
                            </span>
                            <span class="sequence-value"> 
                                <span> Value </span> 
                            </span>
                            <span class="sequence-requesttime"> 
                                <span> Time </span> 
                            </span>
                            <span class="sequence-requesttime"> 
                                <span> Status </span>
                            </span>
                    </span>
                    <Divider type="dashed" />
                </div>
                
                    <div v-for="(action,index) in sequenceWithComments" :key="action" :index="index">
                        <span class="comment-row" v-if="action.status1 === 'comment'">
                            <i class="pi pi-comment" style="margin-right: 1rem;"></i>
                            {{ action.value }} 
                        </span>
                        <span :class="{'current-step': $store.getters.actionsToDo.find(action => !action.is_done) === action}" class="sequence-row" v-else>
                            <span class="sequence-step"> 
                                <!-- <span> Step: </span>  -->
                                <span> {{index}}. </span>
                            </span>
                            <span class="sequence-action">
                                <!-- <span> Action: </span> -->
                                <span> {{action.status2?.label}}</span> 
                                <!-- <span class="sequence-comment"> {{action.comment}} </span> -->
                            </span>
                            <span class="sequence-value"> 
                                <!-- <span> Value: </span>  -->
                                <span class="sequence-value-number"> {{action.status3}} </span> 
                            </span>
                            <span class="sequence-requesttime"> 
                                <!-- <span> Time: </span>  -->
                                <span>{{action.time}}s </span>
                            </span>
                            <span class="sequence-isdone"> 
                                <i v-if="action.is_done === 'done'" class="pi pi pi-check" style="color: var(--green1)"></i>
                                <i v-else-if="action.is_done === 'failed'" class="pi pi-times" style="font-size: 1rem;color: var(--red1)"></i>
                                <i v-else class="pi pi-spin pi-spinner" style="font-size: 1rem"></i>
                            </span>
                        </span>    
                </div>
            </div>
            <div v-else>
                <div class="error-message" v-if="$store.getters.activeSequence?.error">
                    <i class="pi pi-exclamation-triangle"></i>
                    <span>{{ $store.getters.activeSequence.error }}</span> 
                </div>
                <div v-else> No active sequence, go to sequencer </div>
            </div>
        </Sidebar>

        <transition name="context-menu">
            <ContextMenu :open="show_contextmenu" :top="mousey + 'px'" :left="mousex + 'px'" @close="closeContextmenu()" class="font-2-basic">
                <it-dropdown-item class="dropdown_item" icon="library_add" v-if="element_index === -1" divided @click="toggleAddElementDialog();closeContextmenu()"> Add element </it-dropdown-item>
                <it-dropdown-item class="dropdown_item" icon="edit" v-if="element_index != -1" divided @click="toggleEditElementDialog();closeContextmenu()"> Edit element </it-dropdown-item>
                <it-dropdown-item class="dropdown_item" icon="delete"  v-if="element_index != -1" divided @click="deleteElement();closeContextmenu()"> Delete element </it-dropdown-item>
            </ContextMenu>
        </transition>

        <teleport to="body">
            <base-dialog :top="'10'" :width="'60'" :open="show_addelement" @close="closeAddElementDialog()">
                <AddElement
                :element-cords="coordinates"
                @close="closeAddElementDialog()"
                >
                Edit element 
                </AddElement>    
            </base-dialog>
        </teleport>

        <teleport to="body">
            <base-dialog :top="'10'" :width="'60'" :open="show_editelement" @close="closeEditElementDialog()">
                <AddElement
                :element-cords="coordinates"
                :component-mode="mode"
                :element-id="$store.getters.configKeys[element_index] ? $store.getters.configKeys[element_index] : null"
                :element-name="item.name ? item.name : null"
                :element-signals="item.signal_names ? item.signal_names.split(',') : null"
                :element-commands="item.signal_commands ? item.signal_commands.split(',') : null"
                :element-units="item.signal_units ? item.signal_units.split(',') : null"
                :element-favs="item.default_fav ? item.default_fav.split(',').map(el => el == 'true' ? el = true : el = false) : []"
                :element-shortcuts="item.signal_shortcuts ? item.signal_shortcuts.split(',') : null"
                :element-type="item.type ? item.type : null" 
                :element-linerotation="item.line_rotation ? Number(item.line_rotation) : null " 
                :element-linetype="item.line_type ? item.line_type : null "
                :element-isvertical="item.is_vertical != null ? item.is_vertical : null"
                :element-diodes="item.diodes ? item.diodes: null"
                :label-properties="item.label_properties ? item.label_properties : null"
                @close="closeEditElementDialog()"
                >
                Edit element 
                </AddElement>    
            </base-dialog>
        </teleport>

        <NavButtons />

        <div class="edit-buttons" v-if="edit_mode">
            <it-switch v-model="copymode" class="it-switch" type="warning" label="Copy mode" />
            <div class="default_warning" v-if="$store.getters.scenarioTags.includes('default') || $store.getters.scenarioTags.includes('Default')">
                <i class="pi pi-exclamation-triangle"></i>
                <span>
                    Before making any edits to the scenario marked as the default, it is crucial to first create a <b>copy</b>. Any updates released will <b>overwrite</b> the default scenario, causing all changes to be lost. <br> To preserve your modifications, please navigate to <b>Settings</b> > <b>Scenario Selection</b> > <b>Create Scenario and create a copy</b>. Learn more in our <a href="https://support.easygen.org/en-US/guides/simulation-in-the-cloud/1-user-scenarios" target="_blank">guide</a>.
            </span>
            </div>
        </div>

        <!-- grid generator -->

        <div class="" ref="gridcontainer" :class="{'move-class': movemode}" v-dragscroll="movemode">
            <span class="simulation-name">{{$store.getters.chosedScenario}}</span>
            <div class="simu-container">
                <div class="oneRow" :style="{zoom: $store.getters.getZoom}" v-for="row in $store.getters.size.rows" :key="row">
                    <div class="simu-gridbox"
                    v-for="column in $store.getters.size.columns"
                    @click.exact="closeContextmenu()"
                    @click.ctrl.right.exact="saveIndex(-1);toggleContextmenu(row,column)"
                    @drop.self.stop.prevent="saveIndex(-1);drop(row,column)" 
                    @dragover.prevent
                    :style="grid_visible" 
                    :ref="'box' + row + '_' + column"  
                    :key="column" >
                    </div>
                </div>
            </div>
        </div>

        <div class="login-loader">
            <it-icon v-if="scenario_loading" class="login-loader-icon" name="settings" color="#900028" />
        </div>

        <div @click="showSequencerSideBar(true)" class="sequencer-icon-wrapper">
            <i class="pi pi-sliders-v sequencer-icon"></i>
        </div>
        <div @click="openMonitor()" class="chart-icon-wrapper">
            <i class="pi pi-chart-line chart-icon"></i>
        </div>

        <!-- grid generator -->

        <!-- item generator -->
        <div v-for="(element,index) in config" :key="element" :index="index" :ref="'element_' + index" v-show="!$store.getters.isLoading"
            >
            <Breaker v-if="element.type === 'breaker' " 
            :signal-name="element.signal_names"
            :cmd-name="element.signal_commands"
            :is-vertical="element.is_vertical"
            :element-ref="index"
            :element-name="element.name"
            @click.ctrl.right.exact="saveIndex(index)"
            @dragstart="dragStart($event, index)"
            draggable="false"
            />
            <EasyGen v-else-if="element.type === 'easygen' || element.type === 'ls6' || element.type === 'gc' || element.type === 'easyi' || element.type === 'inverter' "
            :signal-names="element.signal_names.split(',')"
            :signal-commands="element.signal_commands.split(',')"
            :signal-units="element.signal_units ? element.signal_units.split(','): null"
            :default-fav="element.default_fav ? element.default_fav.split(',').map(el => el == 'true' ? el = true : el = false) : [] "
            :element-ref="index"
            :element-name="element.name"
            :element-type="element.type"
            @click.ctrl.right.exact="saveIndex(index)"
            @dragstart="dragStart($event, index)"
            draggable="true"
            />
            <Line v-else-if="element.type === 'line' "
            :signal-names="element.signal_names"
            :signal-units="element.signal_units"
            :line-type="element.line_type" 
            :line-rotation="Number(element.line_rotation)"
            :element-ref="index"
            :element-name="element.name"
            @click.ctrl.right.exact="saveIndex(index)"
            @dragstart="dragStart($event, index)"
            draggable="false"
            />
            <DiodeItem v-else-if="element.type === 'diode'"
            :signal-names="element.signal_names.split(',')"
            :signal-units="element.signal_units"
            :element-diodes="element.diodes"
            :element-ref="index"
            :element-name="element.name"
            @click.ctrl.right.exact="saveIndex(index)"
            @dragstart="dragStart($event, index)"
            draggable="true"
            />
            <TextLabel v-else-if="element.type === 'text label'"
            :element-ref="index"
            :element-name="element.name"
            :label-properties="element.label_properties"
            @click.ctrl.right.exact="saveIndex(index)"
            @dragstart="dragStart($event, index)"
            draggable="true"
            />
            <SimuItem 
            :signal-names="element.signal_names.split(',')"
            :signal-commands="element.signal_commands.split(',')"
            :signal-units="element.signal_units.split(',')"
            :element-ref="index"
            :element-type="element.type"
            :element-name="element.name"
            @click.ctrl.right.exact="saveIndex(index)"
            @dragstart="dragStart($event, index)"
            draggable="true"
            v-else-if="element.type === 'generator' || element.type === 'mains' || element.type === 'pv' || element.type === 'load' || element.type === 'battery'"
            />
        </div>
        <!-- item generator -->
    </div>

    <base-dialog :top="20" :width="30" :open="this.showDialog">
        <div class="div-center">
           <h2 style="color: var(--font1); margin: 5px;">No scenario selected</h2> 
        </div>
        <!-- <hr> -->
        <div class="div-center">
           <p style="color: var(--font1)">Please go to settings and select a scenario</p> 
        </div>
        <div class="div-center">
            <Button @click="closeDialog" class="p-button p-button-secondary p-button-outlined" label="Ignore" style="margin: 0 5px;" />
            <Button @click="redirectToSettings" class="p-button p-button-success" label="Go to settings" style="margin: 0 5px;" /> 
        </div>
    </base-dialog>


    <base-dialog :top="20" :width="35" :open="this.showSetupDialog">
        <div class="div-center">
            <i class="pi pi-wrench"></i>
           <h2 style="color: var(--font1); margin: 5px;">Run setup sequence?</h2> 
        </div>
        <!-- <hr> -->
        <div class="div-center dialog-content">
           <p style="color: var(--font1); font-size: 0.9rem;">The <b>setup sequence</b> is a feature that configures the parameters of the simulation model, preparing the system with <b>initial conditions</b> and making it ready for operation. <br><br> Advanced users can create their own setup sequences. Check our <a href="https://support.easygen.org/en-US/guides/simulation-in-the-cloud/3-setup-sequences" target="_blank">guide</a> to learn how.</p> 
        </div>
        <div class="div-center">
            <Button @click="hideSetupSequenceDialog()" class="p-button p-button-secondary p-button-outlined" label="Ignore" style="margin: 0 5px;" />
            <Button @click="startSetupSequence()" class="p-button p-button-info" label="Run" style="margin: 0 5px;" /> 
        </div>
    </base-dialog>

    <base-dialog :top="20" :width="35" :open="this.showConnectDialog">
        <div class="div-center">
            <i class="pi pi-link"></i>
           <h2 style="color: var(--font1); margin: 5px;">You are discconnected</h2> 
        </div>
        <!-- <hr> -->
        <div class="div-center dialog-content">
           <p style="color: var(--font1); font-size: 0.9rem;">To interact with the simulation once the model is running, please <b>connect</b> to it through the settings or by clicking the button below.</p> 
        </div>
        <div class="div-center">
            <Button @click="hideConnectDialog()" class="p-button p-button-secondary p-button-outlined" label="Ignore" style="margin: 0 5px;" />
            <Button @click="connectToSimulation()" class="p-button p-button-info" icon="pi pi-link" label="Connect" style="margin: 0 5px;" /> 
        </div>
    </base-dialog>

</template>

<script>
    import Breaker from '@/components/items_library/Breaker.vue'
    import SimuItem from '@/components/items_library/SimuItem.vue'
    import DiodeItem from '@/components/items_library/DiodeItem.vue'
    import TextLabel from '@/components/items_library/TextLabel.vue'
    import EasyGen from '@/components/items_library/EasyGen.vue'
    import Line from '@/components/items_library/Line.vue'
    import NavButtons from '@/components/navbar/NavButtons.vue'
    import ContextMenu from '@/components/ui_items/ContextMenu.vue'
    import AddElement from '@/components/functions/AddElement.vue'
    import BaseDialog from '../components/ui_items/BaseDialog.vue'
    import Toast from 'primevue/toast';
    import Tag from 'primevue/tag';
    import Sidebar from 'primevue/sidebar';
    import Divider from 'primevue/divider';
    import Button from 'primevue/button'
    import router from '../router/index'
    import Message from 'primevue/message';
    import Checkbox from 'primevue/checkbox'

import { setInterval } from 'core-js'
import { nextTick } from 'vue'

    export default {
        components: {
            SimuItem,
            Breaker,
            EasyGen,
            NavButtons,
            TextLabel,
            Line,
            DiodeItem,
            ContextMenu,
            BaseDialog,
            Toast,
            AddElement,
            Sidebar,
            Divider,
            Button,
            Tag,
            Message,
            Checkbox
        },
        data() {
            return {
                mousex: null,
                mousey: null,
                show_contextmenu: false,
                show_editelement: false,
                show_addelement: false,
                x: 1,
                y: 1,
                element_index: null,
                temp_index: -1,
                moving_index: null,
                rows: null,
                columns: null,
                item: null,
                mode: 'creator',
                copymode: false,
                movemode: false,
                sequencer_sidebar: true,
                socket: null,
                get_signals_interval: null,
                scenario_loading: false,
                showDialog: false,
                allow_drop: true,
                chosed_sequence: null
            }
        },
        methods: {

            scrollToCurrentStep() {
                const currentStep = document.querySelector('.current-step');
                
                if (currentStep) {
                    currentStep.scrollIntoView({ behavior: 'smooth', block: 'center' });
                }

            },

            restartSequence() {
                this.$store.dispatch('runSequence', this.$store.getters.activeSequence.name)
            },

            banDrop() {
                this.allow_drop = false
            },
            allowDrop() {
                this.allow_drop = true
            },
            teleport() {
               let counter = 0
               for( const element of this.config ) { 
                   if(!element.empty) {
                        this.$refs[element.loc.split('.').at(-1)].appendChild(this.$refs[`element_${counter}`])
                        counter++   
                   }
               }
            },
            getSignals() {
                if (!this.connectedToSimulation) {
                    return
                }
                const interval = this.$store.getters.refreshRatio
                this.get_signals_interval = setInterval(() => {
                        this.$store.dispatch('getSignals')
                }, interval);
            },
            stopSignals() {
                clearInterval(this.get_signals_interval)
            },  
            toggleContextmenu(value, index) {
                if (!this.edit_mode) {
                    return
                }

                if(this.show_contextmenu) {
                    this.show_contextmenu = !this.show_contextmenu
                }
                this.show_contextmenu = !this.show_contextmenu
                this.x = value
                this.y = index
                this.item = this.config[this.element_index] 
            },
            toggleAddElementDialog() {
                this.mode = 'creator'
                this.show_addelement = !this.show_addelement
            },
            toggleEditElementDialog() {
                this.mode = 'edit'
                this.show_editelement = !this.show_editelement
            },
            closeContextmenu() {
                this.show_contextmenu = false
            },
            closeAddElementDialog() {
                this.show_addelement = false
            },
            closeEditElementDialog() {
                this.show_editelement = false
            },
            saveIndex(index) {
                this.element_index = this.temp_index
                this.temp_index = index
            },
            async deleteElement() {
                await this.$store.dispatch('deleteElement', {index: this.element_index})
            },
            async drop(row,column) {
                if (this.moving_index != null) {
                    if (this.copymode) {
                        await this.$store.dispatch('copyElement', {
                            index: this.moving_index,
                            row: row,
                            column: column  
                        })
                    }
                    else {
                        await this.$store.dispatch('moveElement', {
                            index: this.moving_index,
                            row: row,
                            column: column
                        })
                    }
                }
                this.moving_index = null
            },
            dragStart(event, index) {
                if (!this.edit_mode) {
                    event.preventDefault()
                } else {
                    this.moving_index = index
                }
            },
            async firebaseOperation() {
                await this.$store.dispatch('firebase')
                this.teleportItems()
                if(!this.$store.getters.scenarioSpecified) {
                    this.showDialog = true
                    this.clearGrid()
                }
            },
            clearGrid() {
                this.$store.commit('setEmptyScenarioGrid', {
                    config: [],
                    columns: null,
                    rows: null
                })
            },
            closeDialog() {
                this.showDialog = false
            },
            redirectToSettings() {
                this.closeDialog()
                router.push('/home/settings')
            },
            async showSequencerSideBar(value) {
                await this.$store.dispatch('getListOfSequences')
                this.$store.commit('setSidebar', {visible: value})
            },
            openMonitor() {
                try {
                    const ip = this.$store.getters.httpsVmIp
                    let routeData = this.$router.resolve({name: 'Monitor', params: { ipAdress: ip }});
                    window.open(routeData.href, '_blank', "width=1500,height=600,left=200,top=200");
                }
                catch(e) {
                    console.log(e)
                    window.alert("Connect to simulation to open monitor")
                }
            },
            hideSetupSequenceDialog() {
                this.$store.commit('setSetupFlag', false)
            },
            hideConnectDialog() {
                this.$store.commit('setConnectFlag', false)
            },
            async startSetupSequence() {
                
                this.$store.commit('setSetupFlag', false)
                if (this.$store.getters.userPreferences?.show_preview || this.$store.getters.userPreferences === null) {
                    this.$store.commit('setSidebar', { visible: true })
                }
                const result = await this.$store.dispatch('runSequence', { label: this.$store.getters.setupSequence })
                if (!result) {
                    this.$toast.add({ severity: 'warn', summary: 'Failed to setup', detail: `Double check if ${this.$store.getters.setupSequence} sequence exists`, life: 7000 })
                    return
                }
                this.$toast.add({ severity: 'success', summary: 'Success', detail: `Setup sequence ${this.$store.getters.setupSequence} finished`, life: 7000 })
            },
            async connectToSimulation() {
                this.$store.state.localhost_connection = true
                this.$store.commit('setConnectFlag', false)
                this.$store.dispatch('connectToSimulation', {ip: this.$store.state.simu_connect_ip, port: this.$store.state.simu_connect_port})
            },
            async teleportItems() {
                await nextTick()
                this.teleport()
                this.$refs.gridcontainer.addEventListener("contextmenu", (event) => {
                        event.preventDefault(); 
                        this.mousex = event.clientX; 
                        this.mousey = event.clientY; 
                });
            }
        },
        computed: {
            autoscroll: {
                get() {
                    return this.$store.getters.autoScroll
                },
                set(value) {
                    this.$store.commit('updateAutoscroll', value)
                }
            },
            config() {
                return this.$store.getters.config
            },
            grid_visible() {
                return this.$store.state.grid_show ? {border: '1px dotted var(--color3)'} : {border: '1px dotted rgba(0,0,0,0)'}
            },
            coordinates() {
                return this.x + '_' + this.y
            },
            loader() {
                return this.$store.getters.isLoading
            },
            connectedToSimulation() {
                return this.$store.getters.connectionStatus == 2
            },
            edit_mode() {
                return this.$store.state.grid_show
            },
            sequenceWithComments() {
                return this.$store.getters.actionsToDo.length ? [...this.$store.getters.sequenceComments, ...this.$store.getters.actionsToDo].sort((a, b) => a.time - b.time) : []
            },
            calculatedSimuTime() {
            if (this.$store.getters.simuTime.length > 0) {
                const LTime = this.$store.getters.simuTime[0].value
                const MTime = this.$store.getters.simuTime[1].value

                const seconds = ((MTime * 65536 + LTime) * 0.005).toFixed(3)
                const minutes = Math.floor(seconds/60)
                const milisecondsToShow = (seconds - Math.floor(seconds)).toFixed(3)
                const secondsToShow = Math.floor(seconds) % 60
                const minutesToShow = minutes % 60
                const hoursToShow = Math.floor(minutes/60)
                 


                return `${hoursToShow ? hoursToShow + 'h' : ''} ${minutesToShow ? minutesToShow + 'm' : ''} ${secondsToShow}s ${milisecondsToShow}ms`
            } else {
                return 0
                }
            },
            showSetupDialog() {
                return this.$store.getters.setupFlag === true 
                && this.$store.getters.setupSequence !== null 
                && this.$store.getters.chosedScenario !== null 
                && this.$store.getters.connectionStatus == 2
                && this.$store.getters.userPreferences.show_setup_dialog !== false
            },
            showConnectDialog() {
                return this.$store.getters.connectFlag === true
                && this.$store.getters.chosedScenario !== null 
                && this.$store.getters.connectionStatus !== 2
                && !['no data', 'none'].includes(this.$store.getters.currentModel.toLowerCase()) 
                && this.$store.getters.deviceList.every(device => device.active === true )
            }
        },
        created() {
            if (this.$CookieConsent.getUserPreferences().acceptedCategories.includes('necessary')) {
                const scenario = localStorage.getItem('scenario')
                if (scenario) {
                    this.$store.commit('setUrl', {chosed_simulation: scenario})
                }
                this.firebaseOperation()
            }
        },
        // mounted() {
        //     this.firebaseOperation()
        // },
        watch: {
            config() {
                this.teleportItems()
            }
        }
    }    

</script>

<style scoped>
    .message-bus {
        position: fixed;
        bottom: 1rem;
        left: 0;
        width: 100%;
        z-index: 999;
        
    }
    .simu-container {
        display: flex;
        flex-direction: column;
        box-shadow: 0 2px 8px rgba(0,0,0,0.26);
        border-radius: 10px;
        margin: 1rem 2rem 0.5rem 2rem;
        position: relative;
        /* max-height: 60%; */
        height: 60%;
        padding: 10px;
        overflow: auto;
        word-wrap: break-word;
        background-color: var(--color2);
        padding-bottom: 2rem;
    }
    .simu-container::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        margin-bottom: 10rem;
    }
    .simu-container::-webkit-scrollbar-thumb {
        background: var(--woodward_red) !important;
        border-radius: 10px;
    }
    .simu-container::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    .oneRow {
        display: flex;
        flex-direction: row;
    }
    .simu-gridbox {
        width: 125px;
        height: 125px;
        min-width: 125px;
        display: inline-block;
    }
    .context-elem {
        border-bottom: solid 0.5px black;
        padding: 3px;
    }
    .context-elem:hover {
        cursor: pointer;
        background-color: rgb(212, 211, 211);
    }
    .dropdown_item {
        background-color: var(--color1);
        color: var(--font1);
        transition: background-color 0.2s ease-in;
    }
    .dropdown_item:hover {
        background-color: var(--color3) !important;
    }
    .context-menu-enter-active{
        animation: open-context-menu 0.3s ease-in;
    }
    .toast {
        z-index: 999999 !important;
    }
    .simulation-name {
        position: absolute;
        left: 50px;
        margin-top: 0.8rem;
        color: var(--woodward_grey);
        z-index: 10;
    }
    .sequence-container {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        gap: 1rem;
        height: 2rem;
    }
    .sequence-wrapper {
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow: auto;
        flex-grow: 1;
        margin-bottom: 1rem;
    }
    .sequence-wrapper::-webkit-scrollbar {
        width: 4px;
        height: 4px;
    }
    .sequence-wrapper::-webkit-scrollbar-thumb {
        background: var(--green1) !important;
        border-radius: 10px;
    }
    .sequence-wrapper::-webkit-scrollbar-track {
        background: #f1f1f1;
    }
    .sequence-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 0.5rem;
        padding: 0.3rem;
    }
    .sequence-step {
        display: flex;
        flex-direction: column;
        min-width: 2rem;
    }
    .sequence-action {
        display: flex;
        flex-direction: column;
        min-width: 19rem;
        max-width: 19rem;
        overflow: hidden;
    }
    .header-wrapper {
        display: flex;
        justify-self: flex-start;
        flex-direction: column;
        justify-content: space-between;
        width: 34rem !important;
    }
    .sequence-value {
        display: flex;
        flex-direction: column;
        min-width: 2.8rem;
        max-width: 2.8rem;
        overflow: hidden;
    }
    .sequence-requesttime {
        display: flex;
        flex-direction: column;
        min-width: 3rem;
    }
    .sequence-header {
        display: flex;
        margin-bottom: 2rem;
        font-size: 1rem;
        height: 1.5rem;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .sequence-isdone {
        min-width: 1rem;
    }
    .sequence-comment {
        color: var(--blue1)
    }

    /* .move-class {
        cursor: grab;
    } */

    @keyframes open-context-menu {
        0% {
            max-width: 0px;
            max-height: 0px;
        }
        100% {
            max-width: 300px;
            max-height: 200px;
        }
    }

.login-loader {
    position: absolute;
    top: 50%;
    left: 50%;
}
.login-loader-icon {
    font-size: 50px;
    animation: 2s loading infinite;
}

@keyframes loading{
    from {
        transform: rotate(0deg);  
    }
    to {
        transform: rotate(360deg);
    }
}
.div-center {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3% 0;
    gap: 0.4rem
}
.dialog-content {
    text-align: justify;
}
.div-center > i {
    font-size: 1.3rem;
    color: var(--font1);
}

.sidebar {
    width: 100rem !important;
    height: 100vh !important;
}


.sequencer-icon-wrapper {
    position: absolute;
    top: 17rem;
    right: 0;
    box-shadow:inset 0px 1px 3px 0px #54a3f7;
	background:linear-gradient(to bottom, #007dc1 5%, #0061a7 100%);
	background-color:#007dc1;
	border-radius:5px;
	border:1px solid #124d77;
	display:inline-block;
	cursor:pointer;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:11px 23px;
	text-decoration:none;
	text-shadow:0px -1px 0px #154682;
    color: var(--font3)
}
.sequencer-icon-wrapper:hover {
    background:linear-gradient(to bottom, #0061a7 5%, #007dc1 100%);
	background-color:#0061a7;
}
.sequencer-icon {
    color: #dadada;
}

.chart-icon-wrapper {
    position: absolute;
    top: 19.8rem;
    right: 0;
    box-shadow:inset 0px 1px 3px 0px #a6827e;
	background:linear-gradient(to bottom, #7d5d3b 5%, #634b30 100%);
	background-color:#7d5d3b;
	border-radius:5px;
	border:1px solid #54381e;
	display:inline-block;
	cursor:pointer;
	font-family:Arial;
	font-size:15px;
	font-weight:bold;
	padding:11px 23px;
	text-decoration:none;
	text-shadow:0px -1px 0px #4d3534;
}
.chart-icon-wrapper:hover {
    background:linear-gradient(to bottom, #634b30 5%, #7d5d3b 100%);
	background-color:#634b30;
}
.chart-icon {
    color: #dadada;
}

.close-sequencer-icon {
    font-size: 1.3rem;
}
.close-sequencer-icon:hover {
    cursor: pointer;
    color: var(--woodward_grey);
    transition: color 0.1s;
}
.comment-row {
    display: flex;
    align-items: center;
    width: 100%;
    color: var(--orange);
    overflow: hidden;
    white-space: nowrap;
}
.current-step {
    background-color: var(--color3) !important;
    border-radius: 4px;
    transition: 0.3s all;
}
.preview-header {
    font-weight: 600;
}
.sequencer-name {
    display: flex;
    flex-direction: column;
}
.error-message {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    align-items: center;
    font-size: 1rem;
}
.error-message > i {
    color: var(--red1);

}
.edit-buttons {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
    margin-left: 2rem;
    margin-top: 0.5rem;
}
.default_warning {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.75rem;
    width: fit-content;
    color: var(--font1);
    border: 1px solid transparent;
    padding: 0.2rem;
    animation: blink-border 1s 3;
}

.default_warning > i {
    color: var(--yellow);
}

a {
    color: var(--font3) !important
}

@keyframes blink-border {
  0% {
    border-color: transparent;
  }
  50% {
    border-color: var(--font2);
  }
  100% {
    border-color: transparent;
  }
}

</style>