<template>
    <div>
        <div class="form-container font-4-basic" v-if="elementType != 'generator' " @dragenter.prevent @drag.prevent @dragstart.prevent draggable="false">
            <div>{{ elementName }}</div>
            <div class="close" @click="submit()" > X </div>
                <form v-on:submit.prevent v-if="signalCommands[0] !== ''">
                    <div class="wwd-row" v-for="(command,index) in signalCommands" :key="command" :index="index"> 
                        <div class="si-input"> 
                            <it-input class="si-input" :placeholder="measure_1(command).name" v-model="values[index]"/> 
                        </div>
                        <span class="unit">{{measure_1(command).unit}}</span> 
                    </div>
                    <it-button type="danger" class="si-button" @click="sendSignals(); submit()">Set</it-button>
                </form>
                <div v-else>
                    <span>Signal commands not chosed</span>
                </div>
            </div>
    </div>
</template>

<script>
    export default {
        props: {
            signalNames: Array,
            signalCommands: Array,
            signalUnits: Array,
            elementType: String,
            elementName: String,
            elementValues: Array
        },    
        data() {
            return {
                is_visible: false,
                cycles: -1,
                values: []
            }
        },
        methods: {
            //getting functions from store
            //after clicking set submenu will close
            submit() {
                this.$emit('toggle-submenu')
            },
            sendSignals() {
                for (var i = 0; i < this.signalCommands.length; i++) {
                    if (this.values[i]) {
                        this.$store.dispatch('setSignal', {signal_name: this.signalCommands[i], val: this.values[i], cycles: this.num_of_cycles, vm: this});
                    }
                } 
                this.$emit('saveValues', this.values)
            },
            measure_1(measure) {
                if (measure.split('_').includes('Voltage')) {
                    return {name: 'Voltage', unit: 'V'}
                }
                else if (measure.split('_').includes('Frequency')) {
                    return {name: 'Frequency', unit: 'Hz'}
                }
                else if (measure.split('_').includes('Available')) {
                    return {name: 'Avaiable AP', unit: 'kW'}
                }
                else if (measure.split('_').includes('RampRate')) {
                    return {name: 'Ramprate', unit: '%'}
                }
                else if (measure.split('_').includes('Setpoint')) {
                    return {name: 'Setpoint', unit: '%'}
                }
                else if (measure.split('_').includes('Load') && measure.split('_').includes('Active')) {
                    return {name: 'ActPower', unit: 'kW'}
                }    
                else if (measure.split('_').includes('Load') && measure.split('_').includes('Reactive')) {
                    return {name: 'ReacPower', unit: 'kvar'}  
                }      
                return 'Nothing'
            },
        },
        computed: {
            //checking if number of cycles is != -1 and if is returning number of cycles
            num_of_cycles() {
                if (this.infinity_cycles) {
                    return -1
                }
                return this.cycles 
            },
        },
        mounted() {
            this.values = this.elementValues
            for (var i = 0; i < this.signalCommands.length; i++) {
                this.values.push(null)
            }
            if (this.elementType === 'generator') {
                this.submit()
            }
            
        }, 
    }    
</script>

<style scoped>
.form-container {
    width: 175px;
    padding: 18px 18px;
    background: var(--color1);
    color: var(--font1);
    position: relative;
    z-index: 9999;
}
.si-button {
    background-color: var(--woodward_red) !important;
    border: 1px solid var(--woodward_red) !important;
    width: 100%;
    margin: 2px;
    height: 23px;
}
.si-button:hover {
    background-color: var(--woodward_red-light) !important;
}
.si-input:deep(.it-input-wrapper) {
    height: 50% !important;
    max-width: 90% !important;
    margin: 2px 0px 2px 0px;
}
.si-input:deep(.it-input) {
    padding: 4px 5px !important;
}
.close {
    position: absolute;
    left: 90%;
    top: 5%;
    width: 32px;
    height: 32px;
    opacity: 0.3;
}
.close:hover {
    opacity: 1;
    color: var(--woodward_red);
    cursor: pointer;
}
.unit {
    min-width: 29px;
}
</style>