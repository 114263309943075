<template>
    <div class="wwd-columnd">
        <h2 class="font-2-basic"> Simulation is starting </h2>
        <it-divider />
        <div class="container wwd-column">
            <div v-for="(netsim,index) in netsims" :key="netsim" :index="index">
                <span v-if="!netsim.active"> Netsim {{index + 1}} status: <span class="red"> starting... </span></span>
                <span v-else> Netsim {{index + 1}} status:<span class="green"> OK! </span></span>
            </div>
        </div>
        <div class="loader wwd-center">
            <base-loader class="wwd-center" />
        </div>
    </div>
</template>

<script>
    import BaseLoader from '@/components/ui_items/BaseLoader.vue'
    export default {
        components: {
            BaseLoader
        },
        data() {
            return {
                isLoading: true,
                netsims: null,
                timer: null,
                getting: true,
                simulation_loaded: false

            }
        },
        methods: {
            async netsimStatus() {
                if (this.getting) {
                    const response = await fetch(this.$store.getters.virtualMachineIp + 'v2/netsimStatus')
                    this.netsims = await response.json()
                }
            }
        },
        created() {
            this.$store.dispatch('startSimulation')
            this.timer = setInterval(() => {
                this.netsimStatus()
            }, 500);
        },
        watch: {
            netsims(newValue) {
                if (newValue.at(-1).active === true) {
                    this.getting = false
                    clearInterval(this.timer)
                    setTimeout(() => {             
                        this.$emit('simulation-loaded')
                    }, 3000);
                }
            }
        }
    }
</script>

<style scoped>
.loader {
    width: 100%;
    display: flex;
    align-items: center;
}
.font-2-basic {
    font-size: 18px !important;
}



</style>