<template>
    <div class="wrapper">
        <img class="img" :src="getImg()" :style="['transform: rotate(' + lineRotation + 'deg)', opacity_style]">
        <section class="container" v-if="signal_names != null">
            <span class="segment-label"> {{elementName}} </span>
            <span :class="edit_mode? 'signals-edit' : 'signals'">
                    <div v-for="sig in signals" :key="sig"> 
                        <div v-if="!edit_mode" class="wwd-row">
                            <div class="format-mid">{{ sig.value.toFixed(2) }}</div> 
                            <div class="format-right">{{ sig.unit }}</div>
                        </div>
                        <div v-else class="signals-wrapper">
                            <div class="format-mid-names">{{ sig.name }}
                        </div>
                    </div>
                    </div>
            </span>
        </section>    
    </div>
</template>

<script>
import line from '@/assets/line_horizontal.png';
import t_line from '@/assets/line_t.png';
import square_line from '@/assets/line_square.png';
import cross_line from '@/assets/line_cross.png';
import line_dotted from '@/assets/line_dotted.png';
import line_dotted_square from '@/assets/line_dotted_square.png'
import segment from '@/assets/segment.png'
export default {
    props: {
        lineType: String,
        lineRotation: Number,
        signalUnits: String,
        elementRef: Number,
        elementName: String,
        signalNames: String,
    },
    data() {
        return {
            signal_names: null,
            signal_shortcuts: null,
            signal_units: null
        }
    },
    methods: {
        getImg() {
            if (this.lineType === 'line') {
                return line
            } else if (this.lineType === 't_line') {
                return t_line
            } else if (this.lineType === 'square_line') {
                return square_line
            }  else if (this.lineType === 'cross_line') {
                return cross_line
            }  else if (this.lineType === 'dotted_line') {
                return line_dotted
            }  else if (this.lineType === 'dotted_line_square') {
                return line_dotted_square
            }  else if (this.lineType === 'segment_only')
                return segment     
        },
    },
    computed: {
            //changing opacity after clicking show_singals button
            opacity_style() {
                return this.signalNames ? {opacity: 0.4} : null
            },
            //generating signal list from signal names and saving them as a object and then updating values from model 
            signals() {
                var signals = [];
                let y = 0;
                for (var name of this.signal_names) {
                    signals[y] = {name: name, value: 0, unit: this.signal_units[y]}
                    y += 1
                }
                for(const signal of this.$store.getters.simuSignals) {
                        const finder = signals.findIndex(element => element.name === signal.name);
                        if (finder !== -1) {
                            signals[finder].value = signal.value
                        }
                    }   
                return signals
            },
            segment_label() {
                return (this.signal_names[0].split('_')[0] + ' ' + this.signal_names[0].split('_')[1]).toString();
            },
            edit_mode() {
                return this.$store.state.grid_show
            }
    },
    mounted() {
        if (this.signalNames) {
            this.signal_names = this.signalNames.split(',')
            this.signal_units = this.signalUnits.split(',')
        }
    },
}
</script>

<style scoped>
.img {
    width: 125px;
    height: 125px;
    z-index: 5;
}
.signals {
    top: 30px;
    left: 20px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    font-size: 18px;
    font-weight:600;
    font-family: var(--font-2-basic);
    color: var(--font3);
}
.signals-edit {
    top: 20px;
    left: 5px;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    position: absolute;
    font-size: 18px;
    font-weight:600;
    font-family: var(--font-2-basic);
    color: var(--font3);
}
.wrapper {
    display:flex;
    position: relative;
    width:150px;
    height:145px;
}
.segment-label {
    margin-top: 0px;
    position: absolute;
    font-size: 15px;
    font-weight:600;
    font-family: var(--font-2-basic);
    color: var(--font3);
    width: 150px;
}
.container {
    position: absolute;
}
.signals-wrapper {
    display: flex;
}
.format-mid-names {
    display: flex;
    max-width: 120px;
    overflow: hidden;
    font-size: 1rem;
    margin-top: 0.1rem;
    color: var(--green);
}

</style>