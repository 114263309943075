<template>
    <div class="context-menu" ref="context-menu" v-if="open" :style="{top: top, left: left}"  @contextmenu.prevent="$emit('close')">
        <slot></slot>
    </div>
</template>

<script>
    export default {
        props: {
            open: Boolean,
            top: String,
            left: String   
        }
    }
</script>

<style scoped>
    .context-menu {
        position: fixed;
        max-width: 300px;
        background-color: rgb(255, 255, 255);
        max-height: 200px;
        z-index: 10;
        border: solid 0.3px rgb(161, 160, 160);
    }
</style>