<template>
    <div class="wwd-sb buttonsbar-wrapper">
        
        <div class="loading-backdrop" v-if="saving">
            <span class="backdrop-label"> Saving scenario... </span>
            <BaseLoader />
        </div>
        <div class="wwd-row buttons">    
            <base-button :class="{disabled: !connectedToSimulation}" @click="startSimulation()"> Resume </base-button> 
            <base-button :class="{disabled: !connectedToSimulation}" @click="stopSimulation()"> Pause </base-button>
            <base-button :class="{disabled: !connectedToSimulation || !$store.getters.setupSequence }" @click="startSetupSequence()">Run Setup Sequence </base-button>
            <base-button @click="toggleSequencerDialog()"> Sequencer </base-button>       
            <base-button @click="gridShow()" v-if="$store.getters.userPreferences?.advanced_mode">
                <template #default>
                    {{ grid }}
                </template>
                <template #bottom>
                    {{ gridNeg }}
                </template>
             </base-button> 
            <base-button @click="toggleManageSimulationDialog()"> Manage scenario </base-button>   
            <base-button :class="{disabled: !isModel}" @click="downloadCurrentModelPdf()"> 
                <span v-if="loadingDoc"> Loading... </span>
                <span v-else>Documentation</span>
        
            </base-button> 
        </div>
        <div class="zoomers wwd-row">
            <it-toggle
            class="theme-toggle"
            icons
            round
            v-model="theme_mode"
            :options="['wb_sunny', 'bedtime']"
            />
            <it-icon class="zoomer" name="zoom_out" outlined @click="changeZoom(-0.05)" />
            <it-icon class="zoomer" name="zoom_in" outlined @click="changeZoom(0.05)" /> 
        </div>
        <teleport to="body">
            <transition name="modal">    
                <base-dialog @close="toggleManageSimulationDialog" :open="dialogManageSimulationVisible" :top="'10'" :width="'40'">
                    <ManageSimulation @close-dialog="toggleManageSimulationDialog" />
                </base-dialog>
            </transition>
            <transition class="modal">
                <base-dialog :open="showStartSimuDialog" :top="'20'" :width="'40'">
                    <NetsimCheck @simulation-loaded="closeStartSimuDialog();getSignals()" />
                </base-dialog>
            </transition>
            <transition class="modal">
                <sequencer-dialog @close="toggleSequencerDialog()" :open="show_sequencer_dialog" :width="'88'" :height="'90'">
                    <SequencerInterface @get-signals="getSignals()" @close-dialog="toggleSequencerDialog()" />
                </sequencer-dialog>
            </transition>
        </teleport>
    </div>
</template>

 <script>
    import BaseDialog from '@/components/ui_items/BaseDialog.vue'
    import SequencerDialog from '@/components/ui_items/SequencerDialog.vue'
    import BaseButton from '@/components/ui_items/BaseButton.vue'
    import ManageSimulation from '@/components/functions/ManageSimulation.vue'
    import NetsimCheck from '@/components/functions/NetsimCheck.vue'
    import SequencerInterface from '@/components/functions/SequencerInterface.vue'
    import BaseLoader from '@/components/ui_items/BaseLoader.vue'
    import { mapActions } from 'vuex';
    export default {
        components: {
            BaseDialog,
            SequencerDialog,
            BaseButton,
            ManageSimulation,
            NetsimCheck,
            BaseLoader,
            SequencerInterface
        },
        data() {
            return {
                dialogAddElementVisible: false,
                dialogManageSimulationVisible: false,
                getter: null,
                showStartSimuDialog: false,
                theme_mode: '',
                saving: false,
                show_sequencer_dialog: false,
                open_monitor: false,
                show_monitor_config: false,
                loadingDoc: false
            }
        },
        methods: {
            ...mapActions(['changeZoom','startSimulation', 'stopSimulation']),
            signalShow() {
                this.$store.state.signals_show = !this.$store.state.signals_show    
            },
            gridShow() {
                this.$store.state.grid_show = !this.$store.state.grid_show   
            },
            toggleAddElementDialog() {
                this.dialogAddElementVisible = !this.dialogAddElementVisible
            },
            toggleManageSimulationDialog() {
                this.dialogManageSimulationVisible = !this.dialogManageSimulationVisible
            },
            toggleSequencerDialog() {
                this.show_sequencer_dialog = !this.show_sequencer_dialog
                this.$store.commit('setSidebar', {visible: false})
            },
            toggleMonitorConfig() {
                this.show_monitor_config = !this.show_monitor_config
            },
            startSimulation() {
                this.$store.dispatch('startSimulation');
            },
            closeStartSimuDialog() {
                this.showStartSimuDialog = false
            },
            checkSignalLists() {
                const simu_signals = Object.values(this.$store.getters.simuSignals)
                var signal_names = []
                for (const x of simu_signals) {
                    signal_names.push(x.name)
                }
                var uploaded_signal_names = this.$store.getters.signalSets[this.$store.getters.chosedScenario].split('\n')

                if (uploaded_signal_names.at(-1) === '') {
                    uploaded_signal_names.pop()
                }

                if (signal_names.sort().join('') !== uploaded_signal_names.sort().join('')) {
                    this.$toast.add({severity:'warn', summary: 'Signal list warning', detail: 'Signal list from this simulation is not up to date!', life: 7000})
                }   
            },
            getSignals() {
                const interval = this.$store.getters.refreshRatio
                this.getter = setInterval(() => {
                        this.$store.dispatch('getSignals')
                }, interval);
            },
            async saveChanges() {
                this.saving = true
                await this.$store.dispatch('saveChanges', {vm: this})
                this.saving = false
            },
            async downloadCurrentModelPdf() {
                this.loadingDoc = true
                await this.$store.dispatch('downloadPdfFile', this.downloadName(this.currentModel))
                this.loadingDoc = null
            },
            downloadName(name) {
                return name.split('.')[0].slice(0, -2) + '.pdf'
            },
            async startSetupSequence() {

                if (this.$store.getters.userPreferences?.show_preview || this.$store.getters.userPreferences === null) {
                        this.$store.commit('setSidebar', {visible: true})
                    }
                const result = await this.$store.dispatch('runSequence', {label: this.$store.getters.setupSequence})
                if (!result) {
                    this.$toast.add({severity:'warn', summary: 'Failed to setup', detail: `Double check if ${this.$store.getters.setupSequence} sequence exists`, life: 7000})
                    return 
                } 
                this.$toast.add({severity:'success', summary: 'Success', detail: `Setup sequence ${this.$store.getters.setupSequence} finished`, life: 7000})

            }
        },
        computed: {
            grid() {
                return this.$store.state.grid_show ? 'Edit mode' : 'Simulation mode'
            },
            gridNeg() {
                return !this.$store.state.grid_show ? 'Edit mode' : 'Simulation mode'
            },
            connectedToSimulation() {
                return this.$store.getters.connectionStatus == 2
            },
            sequencesList() {
                return this.$store.getters.sequencesList ? this.$store.getters.sequencesList : null
            },
            currentModel() {
                return this.$store.getters.currentModel
            },
            isModel() {
                return !['No data', 'unkown', 'No model running', 'None'].includes(this.currentModel)
            }
        },
        mounted() {
            return this.$store.getters.getTheme === 'theme-light' ? this.theme_mode = 'wb_sunny' : this.theme_mode = 'bedtime'
        },
        beforeUnmount() {
            this.$store.state.stop_getting = true
            clearInterval(this.getter)
            this.getter = 0
        },
        watch: {
            theme_mode(new_value) {
                if (new_value === 'wb_sunny') {
                    if (this.$CookieConsent.getUserPreferences().acceptedCategories.includes('performance')) {
                        localStorage.setItem('theme', 'theme-light')
                    }
                    this.$store.dispatch('setTheme', {theme: 'theme-light'})
                }
                else if (new_value === 'bedtime') {
                    if (this.$CookieConsent.getUserPreferences().acceptedCategories.includes('performance')) {
                        localStorage.setItem('theme', 'theme-dark')
                    }
                    this.$store.dispatch('setTheme', {theme: 'theme-dark'})
                }
            }
        }
    }
</script>

<style scoped>
.button-set {
    display: flex;
    justify-content: space-between;
}
.buttonsbar-wrapper {
    flex-wrap: wrap;
}
.zoomer:hover {
    cursor: pointer;
}
.zoomers {
    margin-right: 1.7rem;
    order: 2;
}
.buttons {
    display: flex;
    flex-wrap: nowrap;
    margin-left: 0.1rem;
    order: 1;
}
.buttons > * {
    margin: 0 0.1rem;
}
.zoomer {
    font-size: 4rem;
    color: var(--font1);
}
.theme-toggle {
    margin-right: 1.2rem;
    margin-top: 1rem;
    height: 2rem;
}
.live_tv {
    margin: 0.5rem 1rem 0 0;
    font-size: 3rem;
    color: var(--font1)
}
.live_tv:hover {
    cursor: pointer;
}
.disabled:deep(.btn) {
    background-color: var(--gray) !important;
    pointer-events: none !important;
}
#box {
    width: 100vw;
    height: 100vh;
    background-color: red;
}
@media (max-width: 1283px) {
    .zoomers {
        display: flex;
        justify-content: flex-end;
        width: 100%;
        order: 2;
    }
    .buttons {
        order: 1;
    }
}


</style>