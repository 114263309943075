<template>
    <div class="add-element-container">
        <Toast position="bottom-right" />
        <div class="warning-wrapper" v-if="signal_warning">
            <i class="pi pi-exclamation-triangle warning-icon"></i>
            <span class="warning-text"> Some of signals are not matching model signal list! </span>
        </div>
        <div class="add-element-title font-2-basic">
            <span> Add new element </span>
            <div v-tooltip.top="'Signal list'" class="signal-list-wrapper" v-if="$store.getters.signalSet && $store.getters.signalNames">
                <i class="pi pi-list"></i>
                <span class="signal-set">
                    {{ $store.getters.signalSet }}
                </span>
            </div>
            <div v-else>
                <div class="no-signal-list-wrapper">
                    <i class="pi pi-exclamation-circle"></i>
                    <span class="signal-set">
                        No signal lists attached to scenario, or attached scenario has no signals.
                    </span>
                </div>
            </div>
        </div>
        <Divider />
        <div class="add-element-body-wrapper">
            <span class="body-element-title font-2-basic"> Select element type </span>
            <div class="element-selection" :class="{'element-selection--chosed': !!chosed_element}">
                <SelectButton v-model="chosed_element" :options="element_types" optionLabel="name" :disabled="this.componentMode == 'edit'">
                    <template #option="slotProps">
                        <div class="select-option">
                            <img class="add-element-img" :src="isDarkmode ? require(`@/assets/${slotProps.option.img_dark}.png`) : require(`@/assets/${slotProps.option.img}.png`)" />
                            <div>{{slotProps.option.name}}</div>
                        </div>
                    </template>
                </SelectButton>
            </div>
        </div>

        <div v-if="chosed_element" class="container">
        <!-- Wrapper for mains, load, pv, inverter, battery, generator - show/set elements -->
            <Divider v-if="chosed_element" />
            <div class="customize-element-wrapper" v-if="['Mains','Load','Pv','Battery','Generator'].includes(chosed_element.name)">
                <span class="body-element-title font-2-basic"> Customize element </span>
                <div class="customize-element-container">
                    <div class="customize-element-img-wrapper">
                        <span class="label-preview-wrapper">
                            <span> {{item_name}} </span>
                            <div class="signals-preview-wrapper">
                                <div class="signals-preview-items" v-for="(signal,index) in chosed_signals" :key="signal" :index="index">
                                    <span style="font-weight: 600;"> 0.00 {{chosed_units[index]}} </span>
                                </div>
                            </div>
                        </span>
                        <img class="customize-element-img" :src=" isDarkmode ? require(`@/assets/${chosed_element.img_dark}.png`) : require(`@/assets/${chosed_element.img}.png`)" />
                    </div>
                    <div class="customize-element-selectors">
                        <div class="selector-element">
                            <span class="selector-title font-2-basic"> Select maximum 4 signals to show on this block </span>
                            <MultiSelect :autoFilterFocus="true" class="selector-item" v-model="chosed_signals" optionLabel="label" optionValue="value" :maxSelectedLabels="0" :selectionLimit="4" :filter="true" :options="signals" placeholder="Select signals" display="comma"/>
                        </div>
                        <div class="selector-element" v-if="chosed_element.name !== 'Generator'">
                            <span class="selector-title font-2-basic"> Select maximum 4 signals to intreact with model </span>
                            <MultiSelect :autoFilterFocus="true" class="selector-item" v-model="chosed_commands" optionLabel="label" optionValue="value" :maxSelectedLabels="0" :selectionLimit="4" :filter="true" :options="signals" placeholder="Select signals" display="comma"/>
                        </div>
                    </div>
                </div>
            </div>


            <!-- Wrapper for line -->
            <div class="customize-element-wrapper" v-if="['Line'].includes(chosed_element.name)">
                <span class="body-element-title font-2-basic"> Customize element </span>
                <div class="customize-element-container">
                    <div class="customize-element-img-wrapper breaker-wrapper">          
                        <span class="label-preview-wrapper">
                            <div style="margin-top: 1rem" class="item-name-preview line-name"> {{item_name}} </div>
                            <div class="signals-preview-wrapper line-signals">
                                <div class="signals-preview-items" v-for="(signal,index) in chosed_signals" :key="signal" :index="index">
                                    <span style="font-weight: 600;"> 0.00 {{chosed_units[index]}} </span>
                                </div>
                            </div>
                        </span>
                        <div class="rotate-buttons">
                            <it-button @click="rotateItem(-90)" icon="rotate_left"></it-button>
                            <it-button @click="rotateItem(90)" icon="rotate_right"></it-button>
                        </div>
                        <img v-if="line_type" class="customize-element-img" style="transition: all 0.2s" :style="{'transform': `rotate(${item_rotation}deg)`}" :src="require(`@/assets/${line_type.icon}.png`)" />
                        <div class="linetype-select-wrapper">
                            <SelectButton :unselectable="true" class="linetype-select" v-model="line_type" :options="line_options">
                                <template #option="slotProps">
                                    <img style="width:25px; height:25px;" :src="require(`@/assets/${slotProps.option.icon}.png`)">
                                </template>
                            </SelectButton>
                        </div>
                    </div>
                    <div class="customize-element-selectors">
                        <div class="selector-element">
                            <span class="selector-title font-2-basic"> Select maximum 4 signals to show on this block </span>
                            <MultiSelect :autoFilterFocus="true" class="selector-item" v-model="chosed_signals" optionLabel="label" optionValue="value" :maxSelectedLabels="0" :selectionLimit="4" :filter="true" :options="signals" placeholder="Select signals" display="comma"/>
                        </div>
                    </div>
                </div>
            </div>      
        </div>

        <div v-if="chosed_element" class="container">
        <!-- Wrapper for breakers -->
            <div class="customize-element-wrapper" v-if="['Breaker'].includes(chosed_element.name)">
                <span class="body-element-title font-2-basic"> Customize element </span>
                <div class="customize-element-container">
                    <div class="customize-element-img-wrapper breaker-wrapper">
                        <span class="label-preview-wrapper">
                            <span class="item-name-preview line-name"> {{item_name}} </span>
                        </span>
                        <div class="rotate-buttons">
                            <it-button @click="rotateBreakerItem()" icon="rotate_right"></it-button>
                        </div>
                        <img class="customize-element-img" style="transition: all 0.2s; opacity: 1" :style="{'transform': `rotate(${item_rotation}deg)`}" :src="require(`@/assets/${chosed_element.img_dark}.png`)" />
                    </div>
                    <div class="customize-element-selectors">
                        <div class="selector-element">
                            <span class="selector-title font-2-basic"> Select signal to read </span>
                            <MultiSelect :autoFilterFocus="true" style="width: 20rem" class="selector-item" v-model="chosed_signals" optionLabel="label" optionValue="value" :maxSelectedLabels="1" :selectionLimit="1" :filter="true" :options="signals" placeholder="Select signals" display="chip"/>
                            <span class="selector-title font-2-basic"> Select signal command </span>
                            <MultiSelect :autoFilterFocus="true" style="width: 20rem" class="selector-item" v-model="chosed_commands" optionLabel="label" optionValue="value" :maxSelectedLabels="1" :selectionLimit="1" :filter="true" :options="signals" placeholder="Select signals" display="chip"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="chosed_element" class="container">
        <!-- Wrapper for LS6, GC, Easygen, Inverted -->
            <div class="customize-element-wrapper" v-if="['Ls6', 'Gc', 'Easygen', 'Easyi', 'Inverter'].includes(chosed_element.name)">
                <span class="body-element-title font-2-basic"> Customize element </span>
                <div class="customize-element-container">
                    <div class="customize-element-img-wrapper">
                        <span class="label-preview-wrapper">
                            <span class="item-name-preview device-name"> {{item_name}} </span>
                        </span>
                        <img class="customize-element-img__device" :src="require(`@/assets/${chosed_element.img_dark}.png`)" />
                    </div>
                    <div class="customize-element-selectors">
                        <div class="selector-element">
                            <span class="selector-title font-2-basic"> Select maximum 24 signals to show on this block </span>
                            <MultiSelect :autoFilterFocus="true" class="selector-item" v-model="chosed_signals" optionLabel="label" optionValue="value" :showToggleAll="true" :maxSelectedLabels="0" :selectionLimit="24" :filter="true" :options="signals" placeholder="Select signals" display="comma"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="chosed_element" class="container">
        <!-- Wrapper for diode -->
            <div class="customize-element-wrapper" v-if="['Diode'].includes(chosed_element.name)">
                <span class="body-element-title font-2-basic"> Customize element </span>
                <div class="customize-element-container">
                    <div class="customize-element-img-wrapper breaker-wrapper">
                        <span class="label-preview-wrapper">
                            <span class="item-name-preview diode-name"> {{item_name}} </span>
                        </span>
                        <div class="diodes-wrapper wwd-column">
                            <div v-for="(diode, index) in chosed_signals" :key="diode" :index="index" class=" wwd-column">
                                <div class="wwd-row">
                                    <img class="customize-element-img" style="opacity: 1; width: 20px; height: 20px;" :src="require(`@/assets/${chosed_element.img_dark}.png`)" />
                                    <span class="diode-description"> {{chosed_units[index]}} </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="customize-element-selectors">
                        <div class="selector-element">
                            <span class="selector-title font-2-basic"> Signals to bind: </span>
                            <MultiSelect :autoFilterFocus="true" style="width: 20rem" class="selector-item" v-model="chosed_signals" optionLabel="label" optionValue="value" :maxSelectedLabels="0" :selectionLimit="5" :filter="true" :options="signals" placeholder="Select signals" display="comma"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="chosed_element" class="container">
        <!-- Wrapper for text input -->
            <div class="customize-element-wrapper" v-if="['Text label'].includes(chosed_element.name)">
                <span class="body-element-title font-2-basic"> Customize element </span>
                <div class="customize-element-container">
                    <div class="customize-element-text-wrapper">
                        <span class="text-label-wrapper" :style="`font-size: ${font_size}px; color: var(--${font_color.img}); justify-content: ${justify.name}; align-items: ${align.name}`">
                            <span v-for="line in itemName" :key="line" class="text-label-content"> {{line}} </span>
                        </span>
                    </div>
                    <div class="customize-text-selectors">
                        <div class="selector-element">
                            <div class="selector-row">
                                <it-select class="font-select" :options="[14,16,18,20,22,24,26,28,36,40,46,50]" v-model="font_size" label-top="Font size: " />
                            </div>
                            <div class="wwd-column align-wrapper">
                                <span class="info-label"> Font color: </span>
                                <div class="wwd-row align-row">
                                        <div @click="setAlign(item, 'color')" v-for="item in colors" :key="item.name" class="color-icon" :style="`background-color:var(--${item.img})`"></div>
                                </div>
                            </div>
                            <div class="wwd-row">
                                <div class="wwd-column align-wrapper">
                                    <span class="info-label"> Vertical align: </span>
                                    <div class="wwd-row align-row">
                                        <i @click="setAlign(item, 'vertical')" class="align-icon" :class="`pi ${item.icon}`" v-for="item in align_options" :key="item.name"></i>
                                    </div>
                                </div>
                                <div class="wwd-column align-wrapper">
                                    <span class="info-label"> Horizontal align: </span>
                                    <div class="wwd-row align-row">
                                        <i @click="setAlign(item, 'horizontal')" class="align-icon" :class="`pi ${item.icon}`" v-for="item in align_options" :key="item.name"></i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Units customization for mains,load,line,inverter,generator,battery,pv -->
        <div class="container"  v-if="chosed_signals.length > 0">
            <div v-if="['Mains', 'Load', 'Line', 'Generator', 'Battery', 'Pv'].includes(chosed_element.name)">
                <Divider />
                <div class="customize-labels-wrapper" v-if="chosed_signals">
                    <span class="body-element-title font-2-basic"> Customize units </span>
                    <div class="select-labels-list">
                        <div class="select-labels-row" v-for="(signal,index) in chosed_signals" :key="signal" :index="index">
                            <div class="select-arrows">
                                <i  :class="{'arrow-disabled': index == 0}" class="pi pi-arrow-up" @click="moveSignal(index, -1); moveLabel(index, -1); moveFavorite(index, -1)" />
                                <i  :class="{'arrow-disabled': index == chosed_signals.length - 1}" class="pi pi-arrow-down" @click="moveSignal(index, 1); moveLabel(index, 1); moveFavorite(index, 1)" />
                            </div>   
                            <span class="select-labels-signal_name">{{signal}}</span>
                            <it-select class="select-labels-selector" v-model="chosed_units[index]" :options="unitsList(signal, index)" />
                            <it-icon @click="removeSignal(index)" class="remove-icon" name="delete" />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- labels customization for easygen, gc, ls6, diode-->
        <div class="container" v-if="chosed_signals.length > 0">
            <div v-if="['Ls6', 'Gc', 'Easygen','Easyi', 'Diode', 'Inverter'].includes(chosed_element.name)">
                <Divider />
                <div class="customize-labels-wrapper">
                    <span class="body-element-title font-2-basic"> Customize labels</span>
                    <div class="select-labels-list">
                        <div class="select-labels-row--devices" v-for="(signal,index) in chosed_signals" :key="signal" :index="index">
                            <span v-tooltip="signal" class="select-labels-signal_name device-signal ">{{signal}}</span>
                            <it-input :maxlength="chosed_element.name === 'Diode' ? 14 : 19" placeholder="No description" class="select-labels-selector device-label" v-model="chosed_units[index]" /> 
                            <div class="select-arrows">
                                <i  :class="{'arrow-disabled': index == 0}" class="pi pi-arrow-up" @click="moveSignal(index, -1); moveLabel(index, -1); moveFavorite(index, -1)" />
                                <i  :class="{'arrow-disabled': index == chosed_signals.length - 1}" class="pi pi-arrow-down" @click="moveSignal(index, 1); moveLabel(index, 1); moveFavorite(index, 1)" />
                            </div>     
                            <div class="select-favorite wwd-row">
                                <div v-if="chosed_element.name !== 'Diode'">
                                    <it-checkbox icon="star" class="select-favorite" v-model="default_fav[index]" />
                                    <span class="favorite-label"> Favorite </span>
                                </div>
                                <it-icon @click="removeSignal(index)" style="margin-left: 2rem" class="remove-icon" name="delete" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <Divider v-if="chosed_element" />
        <div class="customize-labels-wrapper" v-if="chosed_element">
            <span class="body-element-title font-2-basic"> Item name and confirmation</span>
            <div class="select-labels-list">
                <it-textarea v-if="chosed_element.name === 'Text label'" v-model="item_name" prefix-icon="short_text" label-top="Text content: " class="text-area" />
                <it-input v-else v-model="item_name" prefix-icon="short_text" label-top="Item name: " class="wwd-color" />
                <Button class="submit-button-item" label="Submit" icon="pi pi-check" @click="createElement()" />
            </div>
        </div>

    </div>
</template>

<script>
    import Divider from 'primevue/divider';
    import SelectButton from 'primevue/selectbutton';
    import Button from 'primevue/button';
    import MultiSelect from 'primevue/multiselect';
    import Toast from 'primevue/toast';
    export default {
        props: {
            elementCords: String,
            elementName: String,
            elementId: String,
            elementType: String,
            elementSignals: Array,
            elementCommands: Array,
            elementUnits: Array,
            elementFavs: Array,
            elementLinerotation: Number,
            elementLinetype: String,
            elementIsvertical: Boolean,
            elementDiodes: Number,
            labelProperties: String,
            componentMode: String
        },
        components: {
            Divider,
            SelectButton,
            MultiSelect,
            Button,
            Toast
        },
        data() {
            return {
                element_types: [{'name':'Mains', 'img': 'mains', 'img_dark':'mains_darkmode'},{'name':'Load', 'img': 'load', 'img_dark':'load_darkmode'},{'name':'Line', 'img': 'line_horizontal', 'img_dark':'line_horizontal'},{'name':'Breaker', 'img': 'breaker_open', 'img_dark':'breaker_open'},{'name':'Easygen', 'img': 'easygen', 'img_dark':'easygen'},{'name':'Easyi', 'img': 'easyi', 'img_dark':'easyi'},{'name':'Inverter', 'img': 'inverter', 'img_dark':'inverter'},{'name':'Ls6', 'img': 'ls6', 'img_dark':'ls6'}, {'name':'Gc', 'img': 'gc', 'img_dark':'gc'},{'name':'Generator', 'img': 'generator', 'img_dark':'generator_darkmode'},{'name':'Battery', 'img': 'battery', 'img_dark':'battery_darkmode'},{'name':'Pv', 'img': 'pv', 'img_dark':'pv_darkmode'},{'name':'Diode', 'img': 'green_diode', 'img_dark':'green_diode'},{'name':'Text label', 'img': 'text_label', 'img_dark':'text_label_darkmode'}],
                units: ['V','Hz','kW','kvar','%','kVA','°',''],
                colors: [{'name': 'Red', 'img': 'red'}, {'name': 'Green', 'img': 'green2'},{'name': 'Blue', 'img': 'blue1'} , {'name': 'Silver', 'img': 'silver'}, {'name': 'Yellow', 'img': 'yellow'}, {'name': 'Orange', 'img': 'orange'}],
                chosed_element: null,
                chosed_signals: [],
                chosed_commands: [],
                chosed_units: [],
                default_fav: [true, true, true, true, true, true, true, true, true, true, true, true, true, true, true],
                signals: [],
                model: null,
                item_name: null,
                localization: this.elementCords,
                signal_warning: false,
                item_rotation: 0,
                diodes: 1,
                line_type: null,
                font_size: 14,
                selected_signal_set: null,
                font_color: {name: 'Blue', img: 'blue1'},
                justify: {name: 'flex-start', icon: 'pi-align-left'},
                align: {name: 'flex-start', icon: 'pi-align-left'},
                justify_options: [{name: 'flex-start', icon: 'pi-align-left'},{name:'center', icon: 'pi-align-center'},{name: 'flex-end', icon: 'pi-align-right'}],
                align_options: [{name: 'flex-start', icon: 'pi-align-left'},{name:'center', icon: 'pi-align-center'},{name: 'flex-end', icon: 'pi-align-right'}],
                line_options: [{name: 'line', icon: 'line_horizontal'},{name:'t_line', icon: 'line_t'},{name: 'square_line', icon: 'line_square'},{name: 'cross_line', icon: 'line_cross'},{name: 'dotted_line', icon: 'line_dotted'},{name: 'dotted_line_square', icon: 'line_dotted_square'}, {name: 'segment_only', icon: 'segment'}]
            }   
        },
        computed: {
            signalLists() {
                return this.$store.getters.signalSets ? Object.keys(this.$store.getters.signalSets) : []
            },
            lineImg() {
                return this.line_options.filter((a => { return a.name === this.line_type }))[0]
            },
            itemNumber() {
                return this.elementId ? this.elementId : this.$store.getters.newElementNumber
            },
            isVertical() {
                return this.item_rotation === 0 ? false : true
            },
            itemName() {
                return this.item_name ? this.item_name.split('\n') : this.item_name
            },
            isDarkmode() {
                return this.$store.getters.getTheme === 'theme-light' ? false : true
            },
            isSignalListChoosed() {
                return !!this.$store.state.signal_set 
            }
        },
        methods: {
            setAlign(align, type) {
                if (type === 'vertical') {
                    this.justify = align
                } else if (type === 'horizontal') {
                    this.align = align
                } else if (type === 'color') {
                    this.font_color = align
                }
            },
            updateSignalList() {
                this.signals = []
                if (this.$store.getters.signalNames) {
                    for (const signal of this.$store.getters.signalNames.split('\n')) {
                        this.signals.push({label: signal, value: signal})
                    }
                }
                this.checkSignals()
            },
            unitsList(signal, index) {
                const signal_type = signal.split('_').pop()
                if (signal_type === 'Voltage') {
                    if (!this.chosed_units[index]) {
                        this.chosed_units[index] = 'V'
                    }
                    return ['V', 'kV']
                } else if (signal_type === 'ActivePower') {
                    if (!this.chosed_units[index]) {
                        this.chosed_units[index] = 'kW'
                    }
                    return ['W', 'kW', 'MW']
                } else if (signal_type === 'ReactivePower') {
                    if (!this.chosed_units[index]) {
                        this.chosed_units[index] = 'kvar'
                    }
                    return ['kvar']
                } else if (signal_type === 'Frequency') {
                    if (!this.chosed_units[index]) {
                        this.chosed_units[index] = 'Hz'
                    }
                    return ['Hz', 'kHz']
                }
                else {
                    return this.units
                }
            },
            async createElement() {
                if (this.chosed_element === 'Diode' && this.diodes !== this.chosed_signals.length) {
                    this.$toast.add({severity:'error', summary: 'Failed to create element', detail:'Number of signals should be equal to number of diodes', life: 3000});
                    return
                }
                await this.$store.dispatch('createElement', {
                    loc: "this.$refs.box" + this.localization,
                    item_id: this.itemNumber,
                    signal_commands: this.chosed_commands ? this.chosed_commands.join(','): '',
                    signal_names: this.chosed_signals ? this.chosed_signals.join(','): '',
                    signal_units: this.chosed_units ? this.chosed_units.join(','): '',
                    default_fav: this.default_fav ? this.default_fav.join(',') : '',
                    type: this.chosed_element.name.toLowerCase(),
                    line_rotation: this.item_rotation ? this.item_rotation: '',
                    line_type: this.line_type ? this.line_type.name : '',
                    diodes: this.diodes ? this.diodes : '',
                    is_vertical: this.isVertical,
                    label_properties: `${this.font_size},${this.font_color.img},${this.justify.name},${this.align.name}`,
                    name: this.item_name ? this.item_name: ''
                })
                this.$emit('close') 
            },
            checkSignals() {
                if (!this.chosed_signals) {
                    return
                }
                for (const signal of this.chosed_signals) {
                    if (!this.signals.map((a => { return a.label })).includes(signal)) {
                        this.signal_warning = true
                        return 
                    } else {
                        this.signal_warning = false
                    }
                }
            },
            rotateItem(angle) {
                if (this.item_rotation === 270 && angle > 0) {
                    this.item_rotation = 0
                    return
                }
                if (this.item_rotation === 0 && angle < 0) {
                    this.item_rotation = 270
                    return
                }
                this.item_rotation += angle
            },
            rotateBreakerItem() {
                if (this.item_rotation === 0) {
                    this.item_rotation = 90

                } else if (this.item_rotation === 90) {
                    this.item_rotation = 0
                }
            },
            addDiode(num) {
                if (this.diodes === 5 && num > 0) {
                    return
                }
                if (this.diodes === 1 && num < 0) {
                    return
                }
                this.diodes += num
            },
            moveSignal(index, direction) {
                var element = this.chosed_signals[index];
                this.chosed_signals.splice(index, 1);
                this.chosed_signals.splice(index + direction, 0, element);
            },
            moveLabel(index, direction) {
                var element = this.chosed_units[index];
                this.chosed_units.splice(index, 1);
                this.chosed_units.splice(index + direction, 0, element);
            },
            moveFavorite(index, direction) {
                var element = this.default_fav[index];
                this.default_fav.splice(index, 1);
                this.default_fav.splice(index + direction, 0, element);
            },
            removeSignal(index) {
                this.chosed_signals.splice(index, 1);
                this.chosed_units.splice(index, 1)
            }
        },
        beforeMount() {
            this.updateSignalList()
            if (this.elementCords) {
                this.localization = this.elementCords
            }
            if (this.elementName) {
                this.item_name = this.elementName
            }
            if (this.elementType) {
                this.chosed_element = this.element_types.filter((a => { return a.name === this.elementType.charAt(0).toUpperCase() + this.elementType.slice(1) }))[0]
            }
            if (this.elementSignals) {
                this.chosed_signals = this.elementSignals
            }
            if (this.elementCommands) {
                this.chosed_commands = this.elementCommands
            }
            if (this.elementUnits) {
                this.chosed_units = this.elementUnits
            }
            if (this.elementFavs) {
                this.default_fav = this.elementFavs
            }
            if (this.elementLinerotation) {
                this.item_rotation = this.elementLinerotation
            }
            if (this.elementLinetype) {
                this.line_type = this.line_options.filter((a => { return a.name === this.elementLinetype }))[0]
            }
            if (this.elementDiodes) {
                this.diodes = this.elementDiodes
            }
            if (this.labelProperties) {
                const label_properties_splited = this.labelProperties.split(',')
                this.font_size = label_properties_splited[0]
                this.font_color = this.colors.find((a => {return a.img === label_properties_splited[1]}))
                this.justify = this.justify_options.find(a => {return a.name === label_properties_splited[2]})
                this.align = this.justify_options.find(a => {return a.name === label_properties_splited[3]})
            }
            if (this.elementIsvertical && this.elementType === 'Breaker') {
                if (this.elementIsvertical == true ) {
                    this.item_rotation = 90
                } else {
                    this.item_rotation = 0
                }
            }
            if (this.componentMode == 'edit') {
                this.hint = false
            }
            this.checkSignals()
        },
        watch: {
            chosed_element(newValue) {
                if (!newValue) {
                    return
                }
                if (newValue.name === 'Line' && !this.line_type) {
                    this.line_type = {name: 'line', icon: 'line_horizontal'}
                } else if (!this.line_type)  {
                    this.line_type = ''
                }
                this.item_rotation = 0
                this.chosed_signals = []
                this.chosed_commands = []
                this.chosed_units = []
                
            },
            chosed_signals() {
                if (['Ls6', 'Gc', 'Easygen'].includes(this.chosed_element.name)) {
                    // this.chosed_signals.sort((a,b) => Number(a.split('_').slice(-1)) > Number(b.split('_').slice(-1)) ? 0 : -1)
                    this.chosed_commands = this.chosed_signals
                }
            }
        }
    }
</script>

<style scoped>
.add-element-container {
    display: flex;
    flex-direction: column;
}
.add-element-title {
    display: flex;
    font-size: 1.4rem;
    color: var(--font1);
    justify-content: space-between;
    align-items: center;
}
.title-signals {
    width: 24rem;
}
.warning-wrapper {
    font-size: 1rem;
    color: rgb(198, 0, 0);
}
.add-element-body-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.add-element-img {
    width: 50px;
}
.element-selection {
    max-width: 54%;
    overflow: auto;
    margin: 1rem;
}
.element-selection--chosed {
    max-height: 15rem;
    max-width: 54%;
    overflow: auto;
    margin: 1rem;
}
.element-selection::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}
.element-selection::-webkit-scrollbar-thumb {
    background: var(--color11) !important;
    border-radius: 10px;
}
.element-selection::-webkit-scrollbar-track {
    background: #f1f1f1;
}
.select-option {
    width: 5rem;
}
.select-button {
    margin: 1rem 0 1rem 1rem
}
.body-element-title {
    font-size: 1.3rem;
    color: var(--font1);
}
.diodes-wrapper {
    margin-top: 1.5rem;
    gap: 0.2rem;
}
.customize-element-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.breaker-wrapper {
    margin-bottom: 3rem;
    min-height: 9rem;
}
.customize-element-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90%;
    height: 15rem;
    margin-top: 2rem;
    padding: 1rem 2rem;
}
.customize-element-img {
    width: 140px;
    height: 140px;
    margin-left: 32%;
    opacity: 0.3;
}
.customize-element-img__device {
    width: clamp(140px,100%,150px);
    margin-left: 16%;
}
.customize-element-img-wrapper {
    width: 20rem;
}
.customize-element-text-wrapper {
    width: 100%;
}
.selector-title {
    color: var(--font1);
    margin-left: 2px;
}
.customize-element-selectors {
    display: flex;
    flex-direction: column;
    margin-bottom: 3rem;
}
.customize-text-selectors {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    width: 100%;
    height: 100%;
    margin-bottom: 3rem;
}
.multiselect-item {
    width: 22rem;
}
.customize-labels-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.select-labels-list {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
}
.select-labels-row {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-top: 0.5rem;
    gap: 5rem;
    width: 100%;
}
.select-labels-row--devices {
    display: flex;
    padding: 0.2rem 5rem;
    min-width: 5rem;
    transition: 0.3s all;
}
.select-labels-signal_name {
    width: 18rem;
    max-width: 18rem;
    overflow: hidden;
    margin-right: 1rem;
    color: var(--font3);
}
.device-signal {
    max-width: 14rem !important;
    overflow: hidden;
}
.select-labels-selector {
    width: 9rem !important;
}
.device-label {
    max-height: 1rem !important;
    width: 8rem !important;
}
.submit-button-item {
    color: white;
    border-color: var(--green1);
    background-color: var(--green2);
    margin: 0 auto;
    margin-top: 2rem;
}
.submit-button-item:hover {
    background-color: var(--green3);
    border-color: var(--green1);
}
.label-preview-wrapper {
    position: absolute;
    display: flex;
    flex-direction: column;
    margin-left: 12%;
    margin-top: 1%;
    width: 140px;
    height: 140px;
    color: var(--font3);   
}
.text-label-wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-left: 4rem;
    max-width: 125px;
    height: 125px; 
    max-height: 125px;
    color: var(--font3); 
    border: 1px dotted var(--color3);
    overflow-wrap: anywhere !important;
    word-wrap: anywhere !important;
    white-space:pre;
}
.selector-element {
    display: flex;
    flex-direction: column;
    margin-top: 1rem;
    max-width: 25rem;
    gap: 1.5rem
}
.selector-row {
    display: flex;
    flex-direction: row;
    margin-left: 1.8rem;
    gap: 2rem;
}
.signals-preview-items {
    margin-top: 0.1rem;
}
.line-signals {
    margin-left: 1.2rem;
    margin-top: 2.5rem;
}
.line-name {
    position: absolute;
    margin-bottom: -1rem;
}
.diode-name {
    position: absolute;
    top: -1rem;
}
.device-name {
    position: absolute;
    top: -2rem;
}
.linetype-select-wrapper {
    display: flex;
    margin-top: 1rem;
    width: 100%;
    justify-content: center;
    align-items: center;
}
.linetype-select {
    display: flex;
    flex-direction: row;
}
.colortype-select {
    display: flex;
    flex-direction: row;
    padding: 1rem;
}
.colortype-select:deep(.p-button) {
    display: flex;
    background-color: rgba(255, 255, 255, 0) !important;
    justify-content: center;
    align-items: center;
    width: 40px !important;
    height: 40px !important;
    padding: 8px !important;
    margin: 0 5px !important;
}
.colortype-select:deep(.p-selectbutton .p-button.p-highlight) {
    background-color: red !important;
}
.linetype-select:deep(.p-button) {
    display: flex;
    justify-content: center;
    background-color: rgba(255, 255, 255, 0) !important;
    width: 50px !important;
    height: 50px !important;
}
.rotate-buttons {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-right: 4%;
}
.rotate-buttons > * {
    min-width: 10px;
    margin: 0px 3px;
    background-color: rgba(240, 248, 255, 0);
    border-color: var(--color3);
    box-shadow: none;
    color: var(--color6);
}

.diode-description {
    color: var(--font3);
    font-size: 0.8rem;
    margin: 0.2rem 0 0 0.5rem;
}
.font-color-dropdown {
    height: 2.7rem;
    width: 10rem;
}
.info-label {
    /* position: absolute; */
    margin-left: 1.9rem;
    font-size: 0.8rem;
    opacity: 0.8;
    color: var(--font1);
}
.it-select:deep(.it-input-label) {
    font-size: 0.8rem !important;
    opacity: 0.8 !important;
    color: var(--font1) !important; 
}
.highlight {
    border: 1px solid red;
}
.list-warning {
    position: absolute;
    margin-top: 0rem;
    margin-left: 4.5rem;
    animation: warning-animation 2s infinite;
}
.select-arrows {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
    margin-left: 1rem;
}
.select-arrows > * {
    color: var(--font1);
    font-size: 0.8rem;
}
.select-arrows > *:hover {
    color: var(--green2);
    cursor: pointer;
    font-size: 0.8rem;
}
.arrow-disabled {
    color: var(--color3);
    cursor: not-allowed !important;
}
.select-favorite {
    display: flex;
    align-items: center;
    margin-left: 2rem;
}
.favorite-label {
    font: var(--font-2-basic);
    color: var(--font1);
    font-size: 0.8rem;
}
.remove-icon {
  color: grey;
  transition: 0.3s all;
}
.remove-icon:hover {
  cursor: pointer;
  color: var(--red1)
}
.align-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.9rem
}
.align-icon {
    color: var(--font1);
    font-size: 1.2rem;
    padding: 0.3rem;
    width: 2rem;
}
.color-icon {
    padding: 0.3rem;
    width: 20px;
    height: 20px;
}
.align-icon:hover, .color-icon:hover {
    border: 1px solid var(--color3);
    cursor: pointer;
}
.align-row {
    margin-left: 1.9rem;
    gap: 0.8rem;
}
.signal-set {
    color: var(--font1);
    font-size: 0.8rem;
}
.signal-list-wrapper, .no-signal-list-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
}
.signal-list-wrapper > i {
    color: var(--font3);
}
.no-signal-list-wrapper > i {
    color: var(--font2)
}
@keyframes warning-animation {
    0% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}
</style>